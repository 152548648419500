import useHttp from "shared/api/services/api"; // Импортируем useHttp
import { API_PREFIX } from "shared/api/constants/api"; // Импортируем API_PREFIX

export default class ImageApi {
  static http = useHttp(API_PREFIX.AI_IMAGE); // Указываем префикс для работы с изображениями
  // Метод для получения всех сгенерированных изображений
  static async getAllGeneratedImages({ offset = 0, limit = 24 }) {
    const { data } = await ImageApi.http.get(
      `/list?offset=${offset}&limit=${limit}`
    );
    return data;
  }
  static async getImageAsBase64(image_id: string) {
    const { data } = await ImageApi.http.get(`/${image_id}/as_base64`);
    return data;
  }
  static async getImageUrl(image_id: string) {
    const { data } = await ImageApi.http.get(`/${image_id}/as_url`);
    return data;
  }
  static async updateByUploadFile(image_id: string, uploadFile: any) {
    const { data } = await ImageApi.http.patch(`/${image_id}`, {
      update: uploadFile,
    });
    return data;
  }

  static async updateImage(
    image_id: string,
    image_b64: string,
    image_url: string
  ) {
    const { data } = await ImageApi.http.patch(`/${image_id}`, {
      image_b64,
      image_url,
    });
    return data;
  }

  // Метод для загрузки изображений
  static async uploadImage(file: File) {
    const formData = new FormData();
    formData.append("image", file);

    const { data } = await ImageApi.http.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("Uploaded Image Response:", data); // Выводим данные в консоль
    return data;
  }

  static async deleteImage(image_id: string) {
    const { data } = await ImageApi.http.delete(`/${image_id}`);
    return data;
  }
}

import React, { memo } from "react";
import CoinXs from "shared/assets/icons/coin-ai-xs";
import CoinPicXs from "shared/assets/icons/coin-pic-xs";
import s from "./UserBalance.module.css";
import { useSelector } from "react-redux";
import { formatNumber } from "shared/lib/formatNumber/formatNumber";
import { subscriptionsSelector } from "entities/UserSubPlan";
import IcToken from "shared/assets/icons/IcToken";
import IcPremiumToken from "shared/assets/icons/IcPremiumToken";

export enum BalancePosition {
  COLUMN = "col",
  ROW = "row",
}
const UserBalance = memo(
  ({
    position = BalancePosition.COLUMN,
    className = "",
  }: {
    position?: BalancePosition;
    className?: string;
  }) => {
    const userPlan = useSelector(subscriptionsSelector.userSubPlan);
    return (
      <div className={`flex flex-${position} gap-2 ${className}`}>
        <span className={s.span}>Текущий баланс:</span>
        <div className="flex gap-3">
          <p className={s.p}>
            <IcToken />
            <span>{formatNumber(Math.floor(userPlan?.tokens || 0))}</span>
          </p>
          <p className={s.p}>
            <IcPremiumToken />
            <span>
              {formatNumber(Math.floor(userPlan?.premium_tokens || 0))}
            </span>
          </p>
        </div>
      </div>
    );
  }
);

export default UserBalance;

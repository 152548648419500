import React from "react";
import { createPortal } from "react-dom";
import { ModalOverlay } from "../ModalOverlay/ModalOverlay";
import CardBacking from "../CardBacking/CardBacking";
import { BtnColor } from "../Button/types";
import Button from "../Button/Button";
import XClose from "shared/assets/icons/x-close";
import Loader from "shared/assets/icons/loader";

const DeleteModal = ({
  isOpened,
  onClose,
  width,
  onAccess,
  onCancel,
  isLoading,
  targetContainer = document.body,
}: {
  isOpened: boolean | string;
  onClose: () => void;
  onAccess: () => void;
  isLoading?: boolean;
  width?: number;
  onCancel?: () => void;
  targetContainer?: HTMLElement;
}) => {
  return createPortal(
    <ModalOverlay open={Boolean(isOpened)} onClose={onClose}>
      <CardBacking className="w-[700px] h-fit">
        <div className="flex flex-col gap-12">
          <div className="flex items-center justify-between">
            <h2>Подтверждение</h2>
            <button onClick={onClose}>
              <XClose />
            </button>
          </div>
          <div className="text-center">
            <p>Вы действительно хотите удалить изображение?</p>
            <span className="text-gray-500">
              Данное действие отменить нельзя.
            </span>
          </div>
          <div className="flex gap-3">
            <Button
              color={BtnColor.WHITE}
              onClick={onCancel}
              className="w-full"
            >
              Отменить
            </Button>
            <Button
              color={BtnColor.BLACK}
              className="w-full"
              onClick={onAccess}
            >
              {isLoading ? <Loader /> : "Удалить"}
            </Button>
          </div>
        </div>
      </CardBacking>
    </ModalOverlay>,
    targetContainer
  );
};

export default DeleteModal;

import { createAsyncThunk } from "@reduxjs/toolkit";
import ImageApi from "../api/imagesAPI";
import { imagesActions } from "../../slice";

export const getImageFullUrl = createAsyncThunk(
  "aiImages/getImageFullUrl",
  async ({ id }: { id: string }, { rejectWithValue, dispatch }) => {
    dispatch(imagesActions.setIsError(null));
    try {
      const res = await ImageApi.getImageUrl(id);
      // console.log(res);
      return {
        fullImageUrl: res.url,
        id,
      };
    } catch (e) {
      dispatch(imagesActions.setIsError(e));
      return rejectWithValue(e);
    }
  }
);

import s from "./GeneratingContent.module.css";
import { Filter } from "shared/assets/icons/Filter";
import { Grid } from "shared/assets/icons/grid";
import { useEffect, useState } from "react";
import { classNames } from "shared/lib/classNames/classNames";
import generatingLoader from "shared/assets/images/Generation_Background.svg";
import { BtnColor, BtnSize } from "shared/ui/Button/types";
import Button from "shared/ui/Button/Button";
import { useAppDispatch } from "app/providers/StoreProvider/config/store";
import Download01 from "shared/assets/icons/download-01";
import DotsHorizontal from "shared/assets/icons/dots-horizontal";
import { createPortal } from "react-dom";
import DeleteModal from "shared/ui/DeleteModal/DeleteModal";
import ChevronDown from "shared/assets/icons/chevron-down";
import XClose from "shared/assets/icons/x-close";
import ChevronUp from "shared/assets/icons/chevron-up";
import imageMock from "shared/assets/images/Group_70.png";
import videoMock from "shared/assets/images/empty_video_page.png";
import Menu01 from "shared/assets/icons/menu-01";
import Skeleton from "shared/ui/Skeleton/Skeleton";
import { downloadImage } from "shared/lib/downloadImage";
import { aiImagesThunks } from "pages/content/GeneratingImages/model/services/thunks";
import {
  IMAGE_GPT_MODELS,
  styleOptions,
} from "pages/content/GeneratingImages/constants/settings";
import { GeneratedImage } from "pages/content/GeneratingImages/constants/types";
import NotificationsGroup, {
  TNotificationsGroup,
} from "shared/ui/NotificationsGroup/NotificationsGroup";
import { createErrorNotification } from "shared/lib/createErrorNotification/createErrorNotification";
import ImageModal from "widgets/ItemPreviewModal/ItemPreviewModal";

const ITEM_TYPE_DATA = {
  image: {
    empty_content: imageMock,
    title: "изображения",
  },
  video: {
    empty_content: videoMock,
    title: "видео",
  },
};
const GeneratingContent = ({
  generatingLoadersCount,
  isLoading,
  setPromptText,
  setSidebarOpen,
  generatedItemsList,
  type,
  getGeneratedItemsList,
  isMoreItems,
  isGettingMoreItems,
  isError,
  getFullItem,
  handleDelete,
  itemsCountByRequest = 24,
}: {
  generatingLoadersCount: number;
  isLoading: boolean;
  setPromptText: React.Dispatch<React.SetStateAction<string>>;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  generatedItemsList: GeneratedImage[];
  type: "image" | "video";
  getGeneratedItemsList: () => void;
  isMoreItems: boolean;
  isGettingMoreItems: boolean;
  isError: string | null;
  getFullItem?: any;
  handleDelete: any;
  itemsCountByRequest?: number;
}) => {
  const [isTwoColumn, setIsTwoColumn] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any | null>(null);

  const [openedFunctionedBlockId, setOpenedFunctionedBlockId] = useState<
    string | null
  >(null);
  const [filterOpened, setFilterOpened] = useState(false);
  const [filterItems, setFilterItems] = useState<
    { name: string; label?: string | any }[]
  >([]);
  const [styleFilterParamsOpened, setStyleFilterParamsOpened] = useState(false);
  const [modelFilterParamsOpened, setModelFilterParamsOpened] = useState(false);

  const [notifications, setNotifications] = useState<
    TNotificationsGroup["notifications"]
  >([]);

  useEffect(() => {
    if (isError) {
      createErrorNotification(isError, setNotifications);
    }
  }, [isError]);

  const dispatch = useAppDispatch();

  const openModal = (img: any) => setSelectedItem(img);

  const deleteItem = async (id: string) => {
    setIsDeleteInProgress(true);
    await dispatch(handleDelete({ id }));
    setDeleteItemId(null);
    setIsDeleteInProgress(false);
  };

  const cancelDelete = () => {
    setDeleteItemId(null);
    setIsDeleteInProgress(false);
  };
  const handleFunctionBlockOpen = (e: any, id: string) => {
    e.stopPropagation();
    setOpenedFunctionedBlockId((p) => (p === id ? null : id));
  };
  const functionBtns = [
    {
      title: "Копировать промпт",
      onClick: ({ prompt }: { prompt: string }) => {
        navigator.clipboard.writeText(prompt);
      },
    },
    {
      title: "Архивировать",
      onClick: () => {},
      hidden: true,
    },
    {
      title: "Удалить",
      onClick: ({ id }: { id: string }) => setDeleteItemId(id),
    },
  ];
  useEffect(() => {
    if (!generatedItemsList.length) {
      getGeneratedItemsList();
    }
  }, [generatedItemsList.length]);

  useEffect(() => {
    const closeFunctionBlock = (e: any) => {
      if (!e.target?.classList.contains(".dots-horizontal")) {
        setOpenedFunctionedBlockId(null);
      }
    };
    document.addEventListener("click", closeFunctionBlock);

    const closeFilter = () => {
      setFilterOpened(false);
    };

    document.addEventListener("click", closeFilter);
    return () => document.removeEventListener("click", closeFunctionBlock);
  }, []);
  const filterButtons = [
    {
      title: "Стиль",
      onClick: () => setStyleFilterParamsOpened((p) => !p),
      opened: styleFilterParamsOpened,
      options: styleOptions
        .filter((item, i) => i != 0)
        .map((item) => ({
          name: item.name,
          label: item.label,
        })),
    },
    {
      title: "Модель",
      onClick: () => setModelFilterParamsOpened((p) => !p),
      opened: modelFilterParamsOpened,
      options: Object.keys(IMAGE_GPT_MODELS).map((item) => ({
        name: item,
        label: IMAGE_GPT_MODELS[item as keyof typeof IMAGE_GPT_MODELS],
      })),
    },
  ];

  const handleDownloadItem = async (item: GeneratedImage) => {
    if (type === "image") {
      let itemUrl;

      if (!item.fullImageUrl) {
        const { fullImageUrl } = await dispatch(
          aiImagesThunks.getImageFullUrl({
            id: item.id,
          })
        ).unwrap();

        itemUrl = fullImageUrl;
      } else {
        itemUrl = item.fullImageUrl;
      }
      downloadImage(itemUrl);
    } else {
      downloadImage(item.url);
    }
  };
  // if (isLoading) {
  //   return <Preloader />;
  // }
  const contentType = ITEM_TYPE_DATA[type as keyof typeof ITEM_TYPE_DATA];
  console.log(generatedItemsList);
  return (
    <div className={s.pageItemsContainer}>
      {generatedItemsList.length > 0 ||
      generatingLoadersCount > 0 ||
      isLoading ? (
        <>
          <div className={s.mobileHeader}>
            <Button
              color={BtnColor.CLEAR}
              size={BtnSize.XSMALL}
              className={s.burgerMenu}
              onClick={() => setSidebarOpen(true)}
              animated={false}
              square
            >
              <Menu01 />
            </Button>
          </div>
          <div className={"flex items-center justify-between"}>
            <div className={s.pageItemsBtnBox}>
              <button className={s.pageItemsBtn}>История</button>
              <button className={s.pageItemsBtn} disabled>
                Избранное
              </button>
              <button className={s.pageItemsBtn} disabled>
                Архив
              </button>
            </div>
            <div className={s.pageItemsBtnBox}>
              <button
                className={s.gridBtn}
                onClick={() => setIsTwoColumn((p) => !p)}
              >
                <Grid />
              </button>
              <div className="relative">
                <button
                  disabled
                  className={s.pageItemsFilter}
                  onClick={(e) => {
                    e.stopPropagation();
                    setFilterOpened((p) => !p);
                  }}
                >
                  <Filter />
                  <span>Фильтр</span>
                </button>
                {filterOpened && (
                  <div
                    className={s.pageItemsFilterBox}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className={s.pageItemsFilterBoxHeader}>
                      <p>Фильтр</p>
                      {filterItems.length > 0 && (
                        <button
                          className="flex items-center gap-2"
                          onClick={() => setFilterItems([])}
                        >
                          Очистить все <XClose width={16} height={16} />
                        </button>
                      )}
                    </div>
                    {filterItems.length > 0 && (
                      <div
                        className={classNames(s.pageItemsFilterItems, {}, [
                          "border-b border-gray-200 !pb-4",
                        ])}
                      >
                        {filterItems.map((item) => (
                          <button
                            className={classNames(s.pageItemsFilterOption, {}, [
                              s.active,
                            ])}
                            key={item.name}
                            onClick={() =>
                              setFilterItems((p) => p.filter((i) => i !== item))
                            }
                          >
                            {item.name}
                            <XClose width={16} height={16} />
                          </button>
                        ))}
                      </div>
                    )}

                    <div className="flex items-center flex-col gap-1 p-2">
                      {filterButtons.map(
                        ({ title, onClick, opened, options }) => (
                          <div className="w-full">
                            <div
                              role="button"
                              className={s.pageItemsFilterBtn}
                              onClick={onClick}
                            >
                              <p>{title}</p>
                              {opened ? (
                                <ChevronUp width={16} height={16} />
                              ) : (
                                <ChevronDown width={16} height={16} />
                              )}
                            </div>
                            {opened && (
                              <div className={s.pageItemsFilterItems}>
                                {options.length > 0 &&
                                  options.map((item) => (
                                    <button
                                      className={classNames(
                                        s.pageItemsFilterOption,
                                        {
                                          [s.active]: filterItems.some(
                                            (filterItem) =>
                                              filterItem.name === item.name
                                          ),
                                        }
                                      )}
                                      key={item.name}
                                      onClick={() =>
                                        setFilterItems((p) => [...p, item])
                                      }
                                    >
                                      {item.name}
                                    </button>
                                  ))}
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={s.pageItemsContentContainer}>
            {/* <p className={s.pageItemsDateGenText}>Сегодня</p> */}

            <div>
              <div className="w-full">
                <div
                  className={classNames(s.pageItemsGrid, {
                    [s.twoColumnItems]: isTwoColumn,
                  })}
                >
                  {isLoading &&
                    new Array(12).fill(null).map((_, index) => <Skeleton />)}
                  {generatedItemsList.map((image: any, index: number) => {
                    return image.isLoader ? (
                      <div className={s.pageItemsGridBox} key={index}>
                        <div className={s.preloaderBg}>
                          <img src={generatingLoader} alt="loader" />
                        </div>
                      </div>
                    ) : (
                      <div className={s.pageItemsGridBox}>
                        <div
                          className={s.pageItemsImageContainer}
                          onClick={() => openModal(image)}
                        >
                          {/* <div
                            className={classNames(s.bookmarkBtn, {}, [
                              s.hiddenElement,
                            ])}
                          >
                            <Button
                              color={BtnColor.BLACK}
                              square
                              size={BtnSize.XXSMALL}
                            >
                              <Bookmark />
                            </Button>
                          </div> */}
                          <div
                            className={classNames(s.dotsBtn, {}, [
                              s.hiddenElement,
                              "dots-horizontal",
                            ])}
                          >
                            <Button
                              color={BtnColor.WHITE}
                              square
                              size={BtnSize.XXSMALL}
                              className={classNames("", {
                                [s.active]:
                                  openedFunctionedBlockId === image.id,
                              })}
                              onClick={(e) =>
                                handleFunctionBlockOpen(e, image.id)
                              }
                            >
                              <DotsHorizontal />
                            </Button>
                            {openedFunctionedBlockId === image.id && (
                              <div className={s.functionsBlock}>
                                {functionBtns.map(
                                  (btn) =>
                                    !btn.hidden && (
                                      <Button
                                        key={btn.title}
                                        color={BtnColor.CLEAR}
                                        square
                                        size={BtnSize.XXSMALL}
                                        onClick={(e) => {
                                          e?.stopPropagation();
                                          btn.onClick(image);
                                        }}
                                        animated={false}
                                      >
                                        {btn.title}
                                      </Button>
                                    )
                                )}
                              </div>
                            )}
                          </div>
                          <div
                            className={classNames(s.downloadBtn, {}, [
                              s.hiddenElement,
                            ])}
                          >
                            <Button
                              color={BtnColor.WHITE}
                              square
                              size={BtnSize.XXSMALL}
                              onClick={(e) => {
                                e?.stopPropagation();
                                handleDownloadItem(image);
                              }}
                            >
                              <Download01 />
                            </Button>
                          </div>
                          <img
                            src={
                              type === "video" ? image.image_preview : image.url
                            }
                            alt=""
                          />
                          <div
                            className={classNames(
                              s.pageItemsImageBtnContainer,
                              {},
                              [s.hiddenElement]
                            )}
                          >
                            <Button
                              color={BtnColor.WHITE}
                              onClick={() => {
                                setPromptText(image.prompt);
                              }}
                            >
                              Использовать
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {generatedItemsList.length >= itemsCountByRequest &&
                  isMoreItems &&
                  !isLoading && (
                    <div className="w-full flex justify-center pt-3">
                      <Button
                        size={BtnSize.SMALL}
                        className={s.loadMoreButton}
                        onClick={getGeneratedItemsList}
                        isLoading={isGettingMoreItems}
                      >
                        Загрузить ещё
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="h-[100vh] w-full flex flex-col mb-[150px] gap-12  items-center justify-center">
          <img src={contentType.empty_content} alt="" className="" />
          <h3>Пора начать создавать {contentType.title}</h3>
        </div>
      )}

      {selectedItem !== null &&
        createPortal(
          <ImageModal
            isOpen={Boolean(selectedItem !== null)}
            // @ts-ignore
            item={selectedItem}
            setItem={setSelectedItem}
            items={generatedItemsList}
            type={type}
            getFullItem={getFullItem}
          />,
          document.body
        )}

      {deleteItemId && (
        <DeleteModal
          isOpened={deleteItemId}
          onClose={cancelDelete}
          onAccess={() => deleteItem(deleteItemId)}
          isLoading={isDeleteInProgress}
        />
      )}
      {notifications && (
        <NotificationsGroup
          notifications={notifications}
          //@ts-ignore
          setNotifications={setNotifications}
        />
      )}
    </div>
  );
};

export default GeneratingContent;

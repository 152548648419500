export const UserCircle = (props) => (
  <svg
    width={props.width || "20"}
    height={props.height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10"
      cy="10"
      r="7.5"
      stroke="#98A2B3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <ellipse
      cx="10"
      cy="8.33334"
      rx="2.5"
      ry="2.5"
      stroke="#98A2B3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.14001 15.7075C5.56355 14.2979 6.86148 13.3329 8.33335 13.3333H11.6667C13.1405 13.3328 14.4398 14.3003 14.8617 15.7125"
      stroke="#98A2B3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

import useHttp from "shared/api/services/api";
import { API_PREFIX } from "shared/api/constants/api";
import { TARIFFS_NAMES } from "shared/constants/common";
import { TUser } from "entities/User";
import { TDefaultSettingsSchema } from "../../slices/defaultSettings";

export interface TUserSearchResult {
  id: string;
  name: string;
  surname: string;
  email: string;
  tokens: number;
  premium_tokens: number;
  is_superuser: boolean;
  is_active: boolean;
  created_at: string;
  last_seen: string;
}

export interface TPayments {
  total_payments: number;
  payments: {
    id: string;
    value: string;
    plan_id: string;
    plan_title: string;
    plan_tokens: number;
    plan_premium_tokens: number;
    plan_is_package: boolean;
    description?: string | null;
    status?: string | null;
    currency?: string;
    payment_method_title?: string | null;
    created_at: string; // добавляем это поле
  }[];
}

export interface PaymentActivity {
  payment_id: string;
  created_at: string;
  value: number;
  plan_title: string;
  user_name: string;
  user_surname: string;
  user_email: string;
}

export interface ActionHistory {
  id: string;
  text: string;
  tokens_usage: number;
  created_at: string;
  user_id: string;
  user_name: string;
  user_surname: string;
  user_email: string;
}

export interface TDefaultSettings {
  generate_ai_chat_name?: boolean;
}
export class AdminApi {
  static http = useHttp(API_PREFIX.ADMIN);

  static async getUsersList(
    offset: number,
    limit = 50
  ): Promise<{ users: TUser[]; total_users: number }> {
    const { data } = await AdminApi.http.get(
      `/users/list?offset=${offset}&limit=${limit}`
    );
    return data;
  }
  static async deleteUser(id: string) {
    const { data } = await AdminApi.http.delete(`/users/user/${id}`);
    return data;
  }
  static async applyAdminPlan(planId: string) {
    const { data } = await AdminApi.http.post(
      `/subscriptions/apply_plan/${planId}`
    );
    return data;
  }
  static async applyPlanByEmail(
    email: string,
    planName: (typeof TARIFFS_NAMES)[keyof typeof TARIFFS_NAMES],
    isAnnual = false
  ) {
    const { data } = await AdminApi.http.post(
      `/subscriptions/apply_plan_by_email?email=${email}&plan_name=${planName}&is_annual=${isAnnual}`
    );
    return data;
  }

  static async getUserHistoryById(userId: string, offset = 0, limit = 50) {
    const { data } = await AdminApi.http.get(
      `/subscriptions/history?user_id=${userId}&offset=${offset}&limit=${limit}`
    );
    return data;
  }
  static async getUserPaymentsHistoryById(
    userId: string,
    offset = 0,
    limit = 50
  ): Promise<{ payments: TPayments; total_payments: number }> {
    const { data } = await AdminApi.http.get(
      `/users/${userId}/payments_history?offset=${offset}&limit=${limit}`
    );
    return data;
  }

  static async getPaymentsActivityHistory(
    offset: number = 0,
    limit: number = 25
  ): Promise<PaymentActivity[]> {
    const { data } = await AdminApi.http.get(
      `/payments/history?offset=${offset}&limit=${limit}`
    );
    return data;
  }

  static async getPaymentsData(
    start: string,
    end: string,
    period: "day" | "week" | "month" | "year"
  ) {
    const { data } = await AdminApi.http.post(`/stats/payments`, {
      start,
      end,
      period,
    });
    return data;
  }

  static async getNewUsersData(
    start: string,
    end: string,
    period: "day" | "week" | "month" | "year"
  ) {
    const { data } = await AdminApi.http.post(`/stats/new_users`, {
      start,
      end,
      period,
    });
    return data;
  }

  static async getIpStats(start: string, end: string) {
    const { data } = await AdminApi.http.post(`/stats/requests_stats`, {
      start,
      end,
    });
    return data;
  }
  static async getAiUsage(
    start: string,
    end: string,
    period: "day" | "week" | "month" | "year"
  ) {
    const { data } = await AdminApi.http.post(`/stats/ai_usage`, {
      start,
      end,
      period,
    });
    return data;
  }
  static async getUserSubPlansHistory() {
    const { data } = await AdminApi.http.get("/sub_plans/history");
    return data;
  }

  // Новый метод для вызова GET /api/v1/admin/actions/history
  static async getActionsHistory(
    offset: number = 0,
    limit: number = 25
  ): Promise<ActionHistory[]> {
    const { data } = await AdminApi.http.get(
      `/actions/history?offset=${offset}&limit=${limit}`
    );
    return data;
  }

  static async searchUser(phrase: string): Promise<TUserSearchResult[]> {
    const { data } = await AdminApi.http.get(
      `/users/search?phrase=${encodeURIComponent(phrase)}`
    );
    return data;
  }

  static async getDefaultUserSettings(user_id: string) {
    const { data } = await AdminApi.http.get(`/default_settings/${user_id}`);
    return data;
  }
  static async updateDefaultUserSettings(
    user_id: string,
    newSettings: TDefaultSettingsSchema["settings"]
  ) {
    const { data } = await AdminApi.http.patch(
      `/default_settings/${user_id}`,
      newSettings
    );
    return data;
  }
}

import React, { useRef, memo } from "react";
import Microphone01 from "shared/assets/icons/microphone-01";
import aiApi from "shared/api/services/aiApi";

interface TAudioRecorder {
  setIsRecording: (isRecording: boolean) => void;
  setMediaRecorder: (mediaRecorder: MediaRecorder | null) => void;
  setIsVoiceTranscripting: (isVoiceTranscripting: boolean) => void;
  setUserRequest: React.Dispatch<React.SetStateAction<string>>;
  isCancelledRef: React.MutableRefObject<boolean>;
  className?: string;
}
const AudioRecorder = memo(
  ({
    setIsRecording,
    setMediaRecorder,
    setIsVoiceTranscripting,
    setUserRequest,
    isCancelledRef,
    className = "",
  }: TAudioRecorder) => {
    const audioChunksRef = useRef<Blob[]>([]);

    const startRecording = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);

      recorder.ondataavailable = async (event: BlobEvent) => {
        audioChunksRef.current.push(event.data);
      };

      recorder.onstop = async () => {
        stream.getTracks().forEach((track) => track.stop());
        // Проверяем, не была ли отменена запись
        if (isCancelledRef.current) {
          audioChunksRef.current = []; // Очищаем массив, если запись была отменена
          isCancelledRef.current = false; // Сбрасываем состояние отмены
          return;
        }
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });

        await sendAudioToServer(audioBlob);
        audioChunksRef.current = []; // очистить массив
      };

      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
    };

    const sendAudioToServer = async (audioBlob: Blob) => {
      // const formData = new FormData();
      // formData.append("audio", audioBlob, "recording.wav");
      try {
        const { transcription } = await aiApi.voiceToText(audioBlob);
        setIsVoiceTranscripting(false);
        setUserRequest((p: string) => p + transcription);
      } catch (error) {
        console.error("Ошибка при отправке аудио:", error);
      }
    };
    return (
      <>
        <button onClick={startRecording} className={className}>
          <Microphone01 size="20px" />
        </button>
      </>
    );
  }
);

export default AudioRecorder;

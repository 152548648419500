import useHttp from "shared/api/services/api"; // Импортируем useHttp
import { API_PREFIX } from "shared/api/constants/api"; // Импортируем API_PREFIX

export default class SpecialApi {
  static http = useHttp(API_PREFIX.SPECIAL_OFFERS); 

  // Метод для получения всех специальных предложений
  static async getSpecialOffers() {
    const { data } = await SpecialApi.http.get("");
    return data;
  }

  // Метод для применения специального предложения по offer_id
  static async applySpecialOffer(offerId: string) {
    try {
      const { data } = await SpecialApi.http.post(`/apply_special_offer/${offerId}`);
      return data; // Возвращаем данные ответа
    } catch (error) {
      console.error("Ошибка при применении специального предложения:", error);
      throw error; // Пробрасываем ошибку дальше
    }
  }
}

const Vector = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="2.5" y="2.5" width="3.33333" height="3.33333" rx="1" stroke="#D0D5DD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<rect x="14.167" y="2.5" width="3.33333" height="3.33333" rx="1" stroke="#D0D5DD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<rect x="14.167" y="14.167" width="3.33333" height="3.33333" rx="1" stroke="#D0D5DD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<rect x="2.5" y="14.167" width="3.33333" height="3.33333" rx="1" stroke="#D0D5DD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.16667 5.83301V14.1663" stroke="#D0D5DD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.8337 5.83301V14.1663" stroke="#D0D5DD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.83301 4.16667H14.1663" stroke="#D0D5DD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.83301 15.8337H14.1663" stroke="#D0D5DD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);

export default Vector;

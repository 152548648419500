const IcToken = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9982 8C18.9982 10.4763 17.7124 12.6522 15.772 13.8966C15.9198 13.2886 15.9982 12.6535 15.9982 12C15.9982 7.58172 12.4165 4 7.99819 4C7.34472 4 6.70955 4.07835 6.10156 4.22617C7.34597 2.2858 9.52193 1 11.9982 1C15.8642 1 18.9982 4.13401 18.9982 8ZM15 12C15 15.866 11.866 19 8 19C4.13401 19 1 15.866 1 12C1 8.13401 4.13401 5 8 5C11.866 5 15 8.13401 15 12ZM7 9C7 8.44772 7.44772 8 8 8C8.55228 8 9 8.44772 9 9V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V9Z"
      fill="var(--text-primary-color)"
      {...props}
    />
  </svg>
);

export default IcToken;

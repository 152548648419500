import { classNames } from "shared/lib/classNames/classNames";
import s from "./VideoSidebar.module.css";
import { useEffect, useRef, useState } from "react";
import LogoShort from "shared/assets/icons/logo-short";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import ArrowLeft from "shared/assets/icons/arrow-narrow-left";
import UserBalance from "shared/ui/UserBalance/UserBalance";
import Button from "shared/ui/Button/Button";
import { BtnColor, BtnSize } from "shared/ui/Button/types";
import XClose from "shared/assets/icons/x-close";
import ChevronRight from "shared/assets/icons/chevron-right";
import {
  VIDEO_GPT_MODELS,
  VIDEO_MODELS_ASPECT_RATIO,
  VIDEO_MODELS_DURATION,
  VIDEO_MODELS_ICONS,
  VIDEO_MODELS_PRICE,
  VIDEO_MODELS_QUALITY,
  VIDEO_MODELS_URLS,
} from "../../сonstants/common";
import DragDropImg from "../DragDropImg/DragDropImg";
import GeneratingTextarea from "shared/ui/GeneratingTextarea/GeneratingTextarea";
import MuiCustomTooltip from "shared/ui/MuiCustomTooltip/ui/MuiCustomTooltip";
import IcPremiumToken from "shared/assets/icons/IcPremiumToken";
import aiApi from "shared/api/services/aiApi";
import { useSelector } from "react-redux";
import {
  subscriptionsSelector,
  userSubPlanActions,
} from "entities/UserSubPlan";
import { TARIFFS_NAMES } from "shared/constants/common";
import { useAppDispatch } from "app/providers/StoreProvider/config/store";
import LimitsModal from "features/LimitsModal/LimitsModal";
import { videosActions } from "../../model/slice";
import generatingLoader from "shared/assets/images/Generation_Background.svg";
import { PopupSettingModel } from "pages/content/GeneratingImages";
import NotificationsGroup, {
  TNotificationsGroup,
} from "shared/ui/NotificationsGroup/NotificationsGroup";
import { videoSelectors } from "../../model/selectors";
import { createErrorNotification } from "shared/lib/createErrorNotification/createErrorNotification";

const TEXTAREA_HEIGHT = 78;
const TEXTAREA_MAX_HEIGHT = 222;

enum VIDEO_GENERATION_TYPE {
  IMAGE = "image_to_video",
  TEXT = "text_to_video",
}
const generationsMode = [
  {
    id: "normal",
    title: "Нормально",
    tooltip:
      "Первоклассные визуальные эффекты с потрясающей детализацией требуют большего времени",
  },
  {
    id: "fast",
    title: "Быстро",
    tooltip: "Хорошие результаты, когда скорость превыше всего",
  },
] as const;

const tooltipProps = {
  isBorder: false,
  placement: "bottom",
  zIndex: "1000",
  isArrow: false,
  color: "semiblack",
  padding: "4px",
} as const;

const generationTypeBtns = [
  {
    id: VIDEO_GENERATION_TYPE.IMAGE,
    title: "Фото в видео",
  },
  {
    id: VIDEO_GENERATION_TYPE.TEXT,
    title: "Текст в видео",
  },
] as const;

export const VideoSidebar = ({
  sidebarOpen,
  setSidebarOpen,
  isGenerating,
  generatedItemsListLength,
  promptText,
  setPromptText,
}: {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
  isGenerating: boolean;
  generatedItemsListLength: number;
  promptText: string;
  setPromptText: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const [openForm, setOpenForm] = useState("");
  const [tokensNeeded, setTokensNeeded] = useState(0);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [familyName, familyModels] = Object.entries(VIDEO_GPT_MODELS)[0];
  const firstModelName = Object.entries(familyModels)[0][0];
  const [notifications, setNotifications] = useState<
    TNotificationsGroup["notifications"]
  >([]);

  const [selectedModel, setSelectedModel] = useState({
    familyName,
    modelName: firstModelName,
    tokens_usage: Object.entries(familyModels)[0][1].tokens_usage,
  });

  const [generationError, setGenerationError] = useState<Error | null>(null);

  const [generationType, setGenerationType] = useState<VIDEO_GENERATION_TYPE>(
    VIDEO_GENERATION_TYPE.IMAGE
  );

  const [generationMode, setGenerationMode] = useState<"fast" | "normal">(
    "normal"
  );
  const [videoQuality, setVideoQuality] = useState("360p");
  const [videoDuration, setVideoDuration] = useState(
    VIDEO_MODELS_DURATION[
      selectedModel.modelName as keyof typeof VIDEO_MODELS_DURATION
    ][generationMode][0]
  );
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [unwantedContentPrompt, setUnwantedContentPrompt] = useState("");
  const [isLimitsModalOpen, setIsLimitsModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const isError = useSelector(videoSelectors.isError);
  const userPlan = useSelector(subscriptionsSelector.userSubPlan);

  const buttonDisabled =
    generationType === VIDEO_GENERATION_TYPE.IMAGE
      ? !imageFile || !promptText.trim()
      : !promptText.trim();

  const userPlanName = userPlan?.maxUserPlan.title;
  const isFreePlan = userPlanName === TARIFFS_NAMES.FREE;
  const modelName = selectedModel.modelName;

  useEffect(() => {
    const url = window.location.href;
    if (url.includes(VIDEO_GENERATION_TYPE.IMAGE)) {
      setGenerationType(VIDEO_GENERATION_TYPE.IMAGE);
    } else if (url.includes(VIDEO_GENERATION_TYPE.TEXT)) {
      setGenerationType(VIDEO_GENERATION_TYPE.TEXT);
    }
  }, []);

  // TODO ???
  useEffect(() => {
    const openForm = () => setOpenForm("");
    document.addEventListener("scroll", openForm);
    return () => {
      document.removeEventListener("scroll", openForm);
    };
  }, []);
  // TODO create function with heights in params
  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = TEXTAREA_HEIGHT + "px"; // Сброс высоты
      const height = textarea.scrollHeight + 5; // Высота контента
      if (height >= TEXTAREA_HEIGHT) {
        textarea.style.height = Math.min(height, TEXTAREA_MAX_HEIGHT) + "px"; // Установка высоты, не превышающей maxHeight
      }
    }
    if (
      !unwantedContentPrompt &&
      textarea &&
      textarea?.style.height !== TEXTAREA_HEIGHT + "px"
    ) {
      textarea.style.height = TEXTAREA_HEIGHT + "px";
    }
  }, [unwantedContentPrompt, textareaRef.current]);

  useEffect(() => {
    setTokensAmount();
  }, [modelName, videoQuality, generationMode, videoDuration]);
  useEffect(() => {
    if (isError || generationError) {
      createErrorNotification(isError || generationError, setNotifications);
    }
  }, [isError, generationError]);

  useEffect(() => {
    setVideoDuration(
      // @ts-ignore
      VIDEO_MODELS_DURATION[modelName as keyof typeof VIDEO_MODELS_DURATION][
        generationMode
      ][0]
    );
  }, [videoQuality, generationMode]);
  const handleSelectModel = (data: any) => {
    setSelectedModel(data);
    setOpenForm("");
  };
  const handleOpenForm = (type: string) =>
    setOpenForm((prev) => (prev === type ? "" : type));

  const handleChangeUnwantedPromptText = (e: any) => {
    const text = e.target.value;

    if (text.length <= 2000) {
      setUnwantedContentPrompt(text);
    }
  };

  const setTokensLimitOpened = () =>
    dispatch(userSubPlanActions.setIsTokensLimitShown(true));

  const setTokensAmount = () => {
    setTokensNeeded(
      // @ts-ignore
      VIDEO_MODELS_PRICE[modelName as keyof typeof VIDEO_MODELS_PRICE][
        `${videoQuality} ${generationMode} ${videoDuration}`
      ]
    );
  };

  const handleGenerateVideo = async () => {
    if (isFreePlan && selectedModel.familyName !== "PixVerse V3.5") {
      setIsLimitsModalOpen(true);
      return;
    }
    if (userPlan && userPlan?.premium_tokens < tokensNeeded) {
      setTokensLimitOpened();
      return;
    }
    if (window.innerWidth < 1160) {
      setSidebarOpen(false);
    }
    dispatch(
      videosActions.updateVideos({
        loader: {
          id: Date.now(),
          isLoader: true,
          url: generatingLoader,
        },
      })
    );
    const link =
      "video_" +
      VIDEO_MODELS_URLS[modelName as keyof typeof VIDEO_MODELS_URLS] +
      "_" +
      generationType;

    let data = {
      prompt: promptText,
      duration: videoDuration,
    } as any;

    let headers = {};
    if (modelName === "PixVerse V3.5") {
      data = {
        ...data,
        aspect_ratio: aspectRatio,
        model: "v3.5",
        motion_mode: generationMode,
        negative_prompt: unwantedContentPrompt,
        quality: videoQuality,
        seed: 0,
        // style: "", // "expected": "'anime', '3d_animation', 'clay', 'comic' or 'cyberpunk'"
      };
      if (generationType === VIDEO_GENERATION_TYPE.IMAGE) {
        headers = { "Content-Type": "multipart/form-data" };

        const requestData = data;

        data = new FormData();
        data.append("request", JSON.stringify(requestData));

        if (imageFile) {
          data.append("image", imageFile);
        }
      }
    }

    try {
      const res = await aiApi.generateVideo(link, data, headers);
      dispatch(
        userSubPlanActions.changeTokensCount({
          used_tokens: res.tokens_usage,
        })
      );
      dispatch(
        videosActions.updateVideos({
          video: res,
        })
      );
      console.log("Результат генерации видео:", res);
    } catch (error) {
      setGenerationError(error as Error);
      dispatch(
        videosActions.updateVideos({
          error: true,
        })
      );
    }
  };

  return (
    <div
      className={classNames(s.sidebarContainer, {
        [s.sidebarOpen]: sidebarOpen,
      })}
      onClick={() => setSidebarOpen(false)}
    >
      <div
        className={classNames(s.sidebar, {
          [s.collapsed]: !sidebarOpen,
          ["!overflow-visible"]: openForm,
        })}
        id="video-generating-sidebar"
        onClick={(e) => {
          e.stopPropagation();
          setOpenForm("");
        }}
        ref={sidebarRef}
      >
        <div className={s.sidebarHeader}>
          <LogoShort />
          <button
            onClick={() => navigate(RoutePath.AI_VIDEOS)}
            className={s.mobileBackBtn}
          >
            <ArrowLeft />
            <span className={s.mobileBackBtnText}>Вернуться в меню</span>
          </button>
          {(generatedItemsListLength > 0 || isGenerating) && (
            <Button
              color={BtnColor.CLEAR}
              size={BtnSize.XXSMALL}
              className={s.closeMobileBtn}
              onClick={() => setSidebarOpen(false)}
              animated={false}
              square
            >
              <XClose />
            </Button>
          )}
        </div>
        <div className={s.sidebarContentContainer}>
          <div className="flex flex-col gap-4">
            <p className={s.sidebarContainerBoxTitle}>Параметры генерации</p>

            <div
              className={classNames(s.sidebarBoxModal, {
                [s.sidebarBoxModalActive]: openForm === "model",
              })}
              onClick={(e: any) => {
                e.stopPropagation();

                handleOpenForm("model");
              }}
            >
              <div className={s.sidebarBoxBtnTitle}>
                {
                  <img
                    src={
                      VIDEO_MODELS_ICONS[
                        selectedModel.familyName as keyof typeof VIDEO_MODELS_ICONS
                      ]
                    }
                    alt="style-img"
                  />
                }
                <span className={s.sidebarBoxBtnText}>Модель</span>
              </div>
              <div className={s.sidebarBoxSelect}>
                <p className={s.sidebarBoxSelectText}>
                  {selectedModel.modelName}
                </p>

                <ChevronRight width="14px" height="14px" />
              </div>
              {openForm === "model" && (
                <PopupSettingModel
                  onChangeGptModel={handleSelectModel}
                  selectedModel={selectedModel.modelName}
                  gptModels={VIDEO_GPT_MODELS}
                  modelsIcons={VIDEO_MODELS_ICONS}
                />
              )}
            </div>
          </div>

          <div className={s.sidebarGenerationTypeBtnsContainer}>
            {generationTypeBtns.map((btn) => (
              <button
                key={btn.id}
                className={classNames(s.generationTypeBtn, {
                  [s.active]: generationType === btn.id,
                })}
                onClick={(e: any) => {
                  e.stopPropagation();
                  setGenerationType(btn.id);
                }}
              >
                {btn.title}
              </button>
            ))}
          </div>
          {generationType === VIDEO_GENERATION_TYPE.IMAGE && (
            <div>
              <p className={s.sidebarContainerBoxTitle}>Загрузка эталона</p>

              <DragDropImg onFilesLoad={setImageFile} imageFile={imageFile} />
            </div>
          )}
          <div>
            <p className={s.sidebarContainerBoxTitle}>Описание видео</p>
            <GeneratingTextarea
              promptText={promptText}
              setPromptText={setPromptText}
              placeholder="Опиши как оживить фото"
              type="video"
            />
          </div>

          <div>
            <p className={s.sidebarContainerBoxTitle}>Соотношение</p>
            <div
              className={
                s.sidebarGenerationTypeBtnsContainer + " gap-2 !border-none"
              }
            >
              {VIDEO_MODELS_ASPECT_RATIO[
                selectedModel.modelName as keyof typeof VIDEO_MODELS_ASPECT_RATIO
              ].map((btn: string) => (
                <button
                  key={btn}
                  className={classNames(s.toggleTypeBtn, {
                    [s.active]: aspectRatio === btn,
                  })}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setAspectRatio(btn);
                  }}
                >
                  {btn}
                </button>
              ))}
            </div>
          </div>
          <div>
            <p className={s.sidebarContainerBoxTitle}>Качество</p>
            <div
              className={
                s.sidebarGenerationTypeBtnsContainer + " gap-2 !border-none"
              }
            >
              {VIDEO_MODELS_QUALITY[
                selectedModel.modelName as keyof typeof VIDEO_MODELS_QUALITY
              ].map((btn: string) => (
                <button
                  key={btn}
                  className={classNames(s.toggleTypeBtn, {
                    [s.active]: videoQuality === btn,
                  })}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setVideoQuality(btn);
                  }}
                >
                  {btn}
                </button>
              ))}
            </div>
          </div>
          <div>
            <p className={s.sidebarContainerBoxTitle}>Режим генерации</p>
            <div className={s.sidebarGenerationTypeBtnsContainer}>
              {generationsMode.map((btn) => {
                if (
                  modelName === "PixVerse V3.5" &&
                  btn.id === "fast" &&
                  videoQuality === "1080p"
                )
                  return;
                return (
                  <button
                    key={btn.id}
                    className={classNames(s.generationTypeBtn, {
                      [s.active]: generationMode === btn.id,
                    })}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setGenerationMode(btn.id);
                    }}
                  >
                    <MuiCustomTooltip title={btn.tooltip} {...tooltipProps}>
                      <span>{btn.title}</span>
                    </MuiCustomTooltip>
                  </button>
                );
              })}
            </div>
          </div>
          <div>
            <p className={s.sidebarContainerBoxTitle}>
              Длительность <span className="text-gray-500">в секундах</span>
            </p>
            <div className={s.sidebarDurationBtnsContainer}>
              {VIDEO_MODELS_DURATION[
                selectedModel.modelName as keyof typeof VIDEO_MODELS_DURATION
              ][generationMode].map((btn: any) => {
                if (
                  modelName === "PixVerse V3.5" &&
                  btn == "8" &&
                  videoQuality === "1080p"
                )
                  return;
                return (
                  <button
                    key={btn}
                    className={classNames(
                      s.toggleTypeBtn,
                      {
                        [s.active]: videoDuration === btn,
                      },
                      ["!w-full"]
                    )}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setVideoDuration(btn);
                    }}
                  >
                    {btn}
                  </button>
                );
              })}
            </div>
          </div>

          <div>
            <p className={s.sidebarContainerBoxTitle}>Нежелательный контент</p>
            <div
              className={s.sidebarBoxInput}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={s.textareaContainer}>
                <textarea
                  ref={textareaRef}
                  placeholder={
                    "Опиши контент, который не хочешь видеть в видео"
                  }
                  value={unwantedContentPrompt}
                  onChange={handleChangeUnwantedPromptText}
                  className={s.textareaPrompt}
                />
              </div>
            </div>
          </div>
          <Button onClick={handleGenerateVideo} disabled={buttonDisabled}>
            Сгенерировать
            <IcPremiumToken fill="white" />
            {tokensNeeded}
          </Button>
        </div>
        <div className={s.profileContainer}>
          <div className={s.profile}>
            <UserBalance />
          </div>
        </div>
      </div>
      {notifications && (
        <NotificationsGroup
          notifications={notifications}
          //@ts-ignore
          setNotifications={setNotifications}
        />
      )}

      {/* Модальное окно - для бесплатного тарифа для повышения тарифа */}
      <LimitsModal
        open={isLimitsModalOpen}
        onClose={() => setIsLimitsModalOpen(false)}
        title="Перейди на следующий уровень и открой новые нейросети!"
        isTariffInfo // Флаг, что модалка для информации о тарифе
        tariffInfoText={
          <p>
            Поменяй тариф и получи новые возможности в генерации видео! Другие
            модели доступны с тарифа <b>Профи</b>.
          </p>
        }
      />
    </div>
  );
};

import React, { useEffect, useState } from "react";
import ArrowLeft from "shared/assets/icons/arrow-narrow-left";
import ArrowRight from "shared/assets/icons/arrow-narrow-right";
import Copy03 from "shared/assets/icons/copy-03";
import XClose from "shared/assets/icons/x-close";
import Button from "shared/ui/Button/Button";
import { BtnColor, BtnSize } from "shared/ui/Button/types";
import { ModalOverlay } from "shared/ui/ModalOverlay/ModalOverlay";
import { TImageModalProps } from "./types";
import s from "./ItemPreviewModal.module.css";
import { dateTimeFormat } from "shared/lib/dateTimeFormat/dateTimeFormat";
import { classNames } from "shared/lib/classNames/classNames";
import { STYLES_MAP } from "../../pages/content/GeneratingImages/constants/settings";
import { useAppDispatch } from "app/providers/StoreProvider/config/store";
import Skeleton from "shared/ui/Skeleton/Skeleton";

const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;

const ImageModal = ({
  isOpen,
  item,
  setItem,
  items,
  getFullItem,
  type,
}: TImageModalProps) => {
  const [copyPrompt, setCopyPrompt] = useState(false);
  const [itemPos, setItemPos] = useState<number>(0);

  const dispatch = useAppDispatch();
  const isImageType = type === "image";
  useEffect(() => {
    const goToHandler = (event: KeyboardEvent) => {
      // Выполнить действие при нажатии на стрелку влево
      if (event.keyCode === LEFT_ARROW) {
        goToPrev();
      } else if (event.keyCode === RIGHT_ARROW) {
        // Выполнить действие при нажатии на стрелку вправо
        goToNext();
      }
    };
    document.addEventListener("keydown", goToHandler);

    return () => document.removeEventListener("keydown", goToHandler);
  }, [itemPos]);

  // исчезновение кнопок при наведении на картинку
  useEffect(() => {
    const img = document.querySelector(`.${s.img}`);
    const video = document.querySelector(`.${s.videoTypeItem}`);
    const buttons = document.querySelectorAll(`.${s.button}`);
    const buttonContainers = document.querySelectorAll(`.${s.buttonContainer}`);

    const onImgEnter = () => {
      if (buttons) {
        buttons.forEach((button: any) => (button.style.display = "block"));
      }
    };

    const onImgLeave = () => {
      if (buttons)
        buttons.forEach((button: any) => (button.style.display = "none"));
    };
    (img || video)?.addEventListener("mouseenter", onImgEnter);
    buttonContainers.forEach((btn: any) =>
      btn?.addEventListener("mouseenter", onImgEnter)
    );

    (img || video)?.addEventListener("mouseleave", onImgLeave);
    buttonContainers.forEach((btn: any) =>
      btn?.addEventListener("mouseleave", onImgLeave)
    );

    return () => {
      (img || video)?.removeEventListener("mouseenter", onImgEnter);
      (img || video)?.removeEventListener("mouseleave", onImgLeave);
      buttonContainers.forEach((btn: any) =>
        btn?.removeEventListener("mouseenter", onImgEnter)
      );
      buttonContainers.forEach((btn: any) =>
        btn?.removeEventListener("mouseleave", onImgLeave)
      );
    };
  }, [item]);

  useEffect(() => {
    (async () => {
      if (isImageType && item?.id && !item?.fullItemUrl) {
        const { fullImageUrl } = await dispatch(
          getFullItem({ id: item.id }) as any
        ).unwrap();
        // @ts-ignore
        setItem((p) => ({ ...p, fullImageUrl }));
      }
    })();
  }, [item?.id]);
  useEffect(() => {
    setItemPos(items.findIndex((el: any) => el.id === item.id));
  }, [item, items]);

  const onClose = () => setItem(null);

  const imgData = [
    {
      title: "AI Модель",
      description: item?.model,
    },
    {
      title: "Стиль",
      description: STYLES_MAP[item?.style as keyof typeof STYLES_MAP] || "Авто",
    },
    {
      title: "Дата",
      description: dateTimeFormat(item?.created_at),
    },

    {
      title: "Размер",
      description: item?.size,
    },
    {
      title: "Токены",
      description: item?.tokens_usage,
    },
  ];

  const goToPrev = () => {
    if (itemPos >= 0) {
      setItem(() => (itemPos != 0 ? items[itemPos - 1] : items[itemPos]));
    }
  };

  const goToNext = () => {
    if (itemPos <= items.length - 1) {
      setItem(() =>
        itemPos != undefined && itemPos < items.length - 1
          ? items[itemPos + 1]
          : items[itemPos]
      );
    }
  };

  const copyPromptHandler = () => {
    navigator.clipboard.writeText(item?.prompt);
    setCopyPrompt(true);
    setTimeout(() => setCopyPrompt(false), 2000);
  };

  return (
    <ModalOverlay open={isOpen} onClose={onClose} className={s.overlay}>
      <div className={s.container}>
        <div className={classNames(s.blockContainer, {}, [s.sideContainer])}>
          {/* <h3 className="pb-3">Детали изображения</h3> */}
          <div className="flex flex-col w-fit gap-6">
            {item?.prompt && (
              <div>
                <p className={s.secondaryText}>{item.prompt}</p>
                <Button
                  color={BtnColor.GRAY}
                  size={BtnSize.XSMALL}
                  onClick={copyPromptHandler}
                  animated={false}
                  className={"my-1.5"}
                >
                  {copyPrompt ? "Запрос скопирован" : "Скопировать запрос"}
                  <Copy03 />
                </Button>
              </div>
            )}
            <div className="flex flex-col gap-2.5">
              {imgData.map((item) =>
                item.description ? (
                  <div key={item.title}>
                    <p className="text-gray-400">{item.title}</p>
                    <span className="font-semibold">{item.description}</span>
                  </div>
                ) : (
                  <></>
                )
              )}
            </div>
          </div>
        </div>
        <div className={classNames(s.blockContainer, {}, [s.contentContainer])}>
          <div
            className={classNames(
              s.buttonContainer + ` ${s.leftPart}`,
              {
                "cursor-pointer": itemPos !== 0,
                "mb-16": !isImageType,
                "!h-[91%]": !isImageType,
              },
              ["w-full"]
            )}
            onClick={goToPrev}
          >
            {/* {itemPos !== 0 && ( */}
            <Button
              color={BtnColor.GRAY}
              square
              className={s.button}
              disabled={itemPos === 0}
            >
              <ArrowLeft />
            </Button>
            {/* )} */}
          </div>

          <div
            className="h-full flex items-center justify-center w-full"
            id="item-container"
          >
            {isImageType && item?.fullImageUrl ? (
              <img
                src={item.fullImageUrl}
                alt={`Изображение в чате`}
                className={s.img}
              />
            ) : isImageType ? (
              <Skeleton
                width={window.innerWidth < 600 ? "100%" : "600px"}
                height={"700px"}
              />
            ) : (
              <video controls className={s.videoTypeItem} key={item.id}>
                <source src={item.url} type="video/webm" />
                <source src={item.url} type="video/mp4" />
                Ваш браузер не поддерживает встроенные видео :(
              </video>
            )}
          </div>

          <div
            className={classNames(
              s.buttonContainer + ` ${s.rightPart}`,
              {
                "cursor-pointer": itemPos !== items.length - 1,
                "mb-16": !isImageType,
                "!h-[91%]": !isImageType,
              },
              ["w-full"]
            )}
            onClick={goToNext}
          >
            <Button
              color={BtnColor.GRAY}
              square
              className={classNames(s.close, {}, [
                "!ml-auto absolute top-4 right-4",
              ])}
              onClick={(e: any) => {
                e.stopPropagation();
                onClose();
              }}
              size={BtnSize.XXSMALL}
            >
              <XClose />
            </Button>
            {/* {itemPos !== items.length - 1 && ( */}
            <Button
              color={BtnColor.GRAY}
              square
              className={classNames(s.button, {}, ["!ml-auto"])}
              disabled={itemPos === items.length - 1}
            >
              <ArrowRight />
            </Button>
            {/* )} */}
          </div>
        </div>
        <div className={s.imagesPreviewContainer}>
          {items.map((item: any, index: number) => (
            <div key={item.id} onClick={() => setItem(item)}>
              <img
                src={isImageType ? item.url : item.url_preview}
                alt={`Изображение в чате`}
                className={classNames(s.imagePreview, {
                  [s.active]: index === itemPos,
                })}
              />
            </div>
          ))}
        </div>
      </div>
    </ModalOverlay>
  );
};

export default ImageModal;

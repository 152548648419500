import { ReactNode } from "react";

export enum BadgeColor {
  BLACK = "black",
  WHITE = "white",
  GRAY = "gray",
  DARK_GRAY = "dark-gray",
  BLUE = "blue",
  LIGHT_BLUE = "light-blue",
  WARNING = "warning",
  LIGHT_WARNING = "light-warning",
  ERROR = "error",
  LIGHT_ERROR = "light-error",
}
export interface TBadge {
  children: ReactNode;
  color: BadgeColor;
  className?: string;
}

import { createSlice } from "@reduxjs/toolkit";
import { TUserSchema } from "../types/TUserSchema";
import { userThunks } from "../services/thunks";

const initialState: TUserSchema = {
  user: null,
  isLoading: false,
  isError: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    isLoading(state, { payload }) {
      state.isLoading = payload;
    },
    isError(state, { payload }) {
      state.isError = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(userThunks.getMe.fulfilled, (state, { payload }) => {
        if (payload) {
          state.user = payload.user;
        }
      })
      .addCase(userThunks.getUserAvatar.fulfilled, (state, { payload }) => {
        if (payload?.avatar && state.user) {
          state.user.avatar_path = payload.avatar.url;
        }
      })
      .addCase(userThunks.updateUserAvatar.fulfilled, (state, { payload }) => {
        if (payload?.updatedAvatar && state.user) {
          state.user.avatar_path = payload.updatedAvatar.url;
        }
      })
      .addCase(userThunks.updateUserData.fulfilled, (state, { payload }) => {
        if (payload) {
          state.user = {
            ...state.user,
            ...payload.user,
          };
        }
      });
  },
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;

const Copy03 = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4527_52392)">
      <path
        d="M5.3335 5.33331V3.46665C5.3335 2.71991 5.3335 2.34654 5.47882 2.06133C5.60665 1.81044 5.81063 1.60647 6.06151 1.47864C6.34672 1.33331 6.72009 1.33331 7.46683 1.33331H12.5335C13.2802 1.33331 13.6536 1.33331 13.9388 1.47864C14.1897 1.60647 14.3937 1.81044 14.5215 2.06133C14.6668 2.34654 14.6668 2.71991 14.6668 3.46665V8.53331C14.6668 9.28005 14.6668 9.65342 14.5215 9.93863C14.3937 10.1895 14.1897 10.3935 13.9388 10.5213C13.6536 10.6666 13.2802 10.6666 12.5335 10.6666H10.6668M3.46683 14.6666H8.5335C9.28023 14.6666 9.6536 14.6666 9.93882 14.5213C10.1897 14.3935 10.3937 14.1895 10.5215 13.9386C10.6668 13.6534 10.6668 13.28 10.6668 12.5333V7.46665C10.6668 6.71991 10.6668 6.34654 10.5215 6.06133C10.3937 5.81044 10.1897 5.60647 9.93882 5.47864C9.6536 5.33331 9.28023 5.33331 8.5335 5.33331H3.46683C2.72009 5.33331 2.34672 5.33331 2.06151 5.47864C1.81063 5.60647 1.60665 5.81044 1.47882 6.06133C1.3335 6.34654 1.3335 6.71991 1.3335 7.46665V12.5333C1.3335 13.28 1.3335 13.6534 1.47882 13.9386C1.60665 14.1895 1.81063 14.3935 2.06151 14.5213C2.34672 14.6666 2.72009 14.6666 3.46683 14.6666Z"
        stroke="#98A2B3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4527_52392">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Copy03;

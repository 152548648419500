export const COMMON_ERROR_TEXT = {
  "Network Error": "Сервер недоступен. Попробуйте позже.",
  "Request failed with status code 400": "Некорректные данные.",
  "Request failed with status code 422": "Некорректные данные.",
  ERR_BAD_REQUEST: "Некорректные данные.",
  REGISTER_USER_ALREADY_EXISTS: "Такой пользователь уже существует.",
  "Not Found": "Пользователь не найден.",
  LOGIN_BAD_CREDENTIALS: "Неверный логин или пароль.",
  "String should have at most 32 characters":
    "Строка должна содержать не более 32 символов.",
  UNKNOWN_ERROR: "Неизвестная ошибка",
  "value is not a valid email address: The email address is not valid. It must have exactly one @-sign.":
    "Некорректная почта.",
  "You don't have access to background replacement, upgrade your subscription plan.":
    "У вас нет прав доступа к замене фона, обновите план подписки.",
  'You can\'t have more documents than 0 with your subscription plan "Профи".':
    'Вы не можете создать больше документов с вашим планом "Профи"',
  "Your audio file is too big, update your subscription plan.":
    "Ваш аудио-файл слишком большой, обновите ваш план подписки.",
  "You have no access to this model, upgrade your subscription plan.":
    "У вас нет доступа к этому модели, обновите ваш план подписки.",
  "value is not a valid email address: An email address must have an @-sign.":
    "Электронная почта должна содержать знак @.",
  "Can't send email, try again with correct one.":
    "Не можем отправить письмо. Попробуйте ещё раз позже.",
  "value is not a valid email address: An email address cannot have a period immediately after the @-sign.":
    "Электронная почта не может содержать точку после знака @.",
  "Invalid code.": "Неверный код",
  "Code expired": "Срок действия кода истек",
  "Insufficient balance. Unable to generate video. Please top up your credits.":
    "Недостаточно средств на сервере. Невозможно создать видео. Обратитесь в поддержку",
  "Code creation tries are too often.": "Слишком частое создание кода.",
  "File of this type is not allowed or file is too big (max file size in 20Mb).":
    "Файл этого типа не разрешен или файл слишком большой (максимальный размер 20Mb).",
  "Service not alvailable.": "Сервис недоступен.",
  "Internal Server Error": "Внутренняя ошибка сервера",
  "Input should be a valid dictionary or object to extract fields from":
    "Данные некорректны",
  //prettier-ignore
  '{\"errors\":[\"You lack sufficient credits to make this request.  Please purchase more credits at https://platform.stability.ai/account/credits and try again.\"],\"id\":\"d5b2fa9206e77006966cb700954fe7b9\",\"name\":\"payment_required\"}':
    "Ошибки сервера. Недостаточно токенов.",
  //prettier-ignore
  '{\"errors\":[\"Your request was flagged by our content moderation system, as a result your request was denied and you were not charged.\"],\"id\":\"48c861bc9f0190e57efa8c795b47df06\",\"name\":\"content_moderation\"}': "Попробуйте ввести другой запрос",
} as const;

export type TCommonError = keyof typeof COMMON_ERROR_TEXT;

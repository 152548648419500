const ENV = process.env.REACT_APP_ENV || "prod";

export const YANDEX_AUTH_REDIRECT_URI =
  process.env.REACT_APP_ENV === "dev"
    ? process.env.REACT_APP_YANDEX_REDIRECT_URI_DEV
    : process.env.REACT_APP_YANDEX_REDIRECT_URI_PROD;

export const YANDEX_AUTH_CLIENT_ID =
  process.env.REACT_APP_ENV === "dev"
    ? process.env.REACT_APP_YANDEX_CLIENT_ID_DEV
    : process.env.REACT_APP_YANDEX_CLIENT_ID_PROD;

export const API_URL_PROD = process.env.REACT_APP_SERVER_PROD;

const API_URL_DEV = process.env.REACT_APP_SERVER_DEV;

export const VERSION = "v1";

export const API_URL = ENV === "prod" ? API_URL_PROD : API_URL_DEV;
export const APP_URL =
  ENV === "prod" ? "https://app.monoai.ru" : "https://dev.monoai.ru";

export const API_PREFIX = {
  AUTH: "auth",
  USERS: "users",
  VIDEO_SOURCE: "video_source",
  VIDEO_SOURCE_GROUP: "video_source_group",
  TEXT_DOCUMENTS: "documents",
  DOCUMENTS_FOLDERS: "folders",
  AI: "ai",
  CHAT_BOT: "ai_chats",
  CHAT_BOT_FOLDER: "ai_chat_folders",
  REFERRALS: "referrals",
  AUDIOS: "audios",
  AIVOICES: "ai_voices",
  AIDUBBINGS: "ai_dubbings",
  BG_REPLACEMENT: "background_replacement",
  AI_IMAGE: "images",
  AI_VIDEO: "videos",
  SUPPORT: "support_chats",
  PAYMENTS: "payments",
  SUBSCRIPTIONS: "subscriptions",
  SUB_PLANS: "sub_plans",
  ACTIONS: "actions",
  ADMIN_USERS: "admin/users",
  ADMIN: "admin",
  SPECIAL_OFFERS: "special_offers/me",
} as const;

export const AUTH_URL = `${API_URL}/${VERSION}/${API_PREFIX.AUTH}`;
export const USERS_URL = `${API_URL}/${VERSION}/${API_PREFIX.USERS}`;

export const VERIFY_RECAPTCHA_URL = `${API_URL}/${VERSION}/verify_recaptcha`;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminApi } from "../../api/AdminApi";
import { TDefaultSettingsSchema } from "entities/Admin/model/slices/defaultSettings";

export const updateDefaultUserSettings = createAsyncThunk(
  "defaultSettings/updateDefaultSettings",
  async (
    {
      user_id,
      newSettings,
    }: { user_id: string; newSettings: TDefaultSettingsSchema["settings"] },
    { dispatch }
  ) => {
    // dispatch(userActions.isError(null));
    try {
      AdminApi.updateDefaultUserSettings(user_id, newSettings);
      return newSettings;
    } catch (e: any) {
      // dispatch(userActions.isError(e));
    }
  }
);

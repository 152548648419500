import { TStateSchema } from "app/providers/StoreProvider/config/types";

export const videoSelectors = {
  generatedVideoList: (state: TStateSchema) =>
    state.aiVideos.generatedVideosList,
  isLoading: (state: TStateSchema) => state.aiVideos.isLoading,
  isError: (state: TStateSchema) => state.aiVideos.isError,
  isMoreVideos: (state: TStateSchema) => state.aiVideos.isMoreVideos,
  isGettingMore: (state: TStateSchema) => state.aiVideos.isGettingMore,
};

import s from "./Badge.module.css";
import { FC, memo } from "react";
import { TBadge } from "./types";
import { classNames } from "../../lib/classNames/classNames";

const Badge: FC<TBadge> = memo(({ children, color, className = "" }) => {
  return (
    <div className={classNames(s.badge, {}, [s[color], className])}>
      {children}
    </div>
  );
});

export default Badge;

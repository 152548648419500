const Users01 = (props) => (
  <svg
    width={props.width || "24"}
    height={props.height || "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 21.0001V19.0001C22 17.1363 20.7252 15.5702 19 15.1261M15.5 3.29088C16.9659 3.88427 18 5.32143 18 7.00012C18 8.67881 16.9659 10.116 15.5 10.7094M17 21.0001C17 19.1364 17 18.2045 16.6955 17.4694C16.2895 16.4893 15.5108 15.7106 14.5307 15.3046C13.7956 15.0001 12.8638 15.0001 11 15.0001H8C6.13623 15.0001 5.20435 15.0001 4.46927 15.3046C3.48915 15.7106 2.71046 16.4893 2.30448 17.4694C2 18.2045 2 19.1364 2 21.0001M13.5 7.00012C13.5 9.20926 11.7091 11.0001 9.5 11.0001C7.29086 11.0001 5.5 9.20926 5.5 7.00012C5.5 4.79098 7.29086 3.00012 9.5 3.00012C11.7091 3.00012 13.5 4.79098 13.5 7.00012Z"
      stroke="#101018"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Users01;

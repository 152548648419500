export const scrollToBottom = (scrollContainerRefCurrent: any) => {
  const targetScrollTop = scrollContainerRefCurrent.scrollHeight;
  let currentScrollTop = scrollContainerRefCurrent.scrollTop;
  const scrollDuration = 200; // Длительность прокрутки (в миллисекундах) //todo 500
  const startTime = Date.now();

  const animateScroll = () => {
    const elapsedTime = Date.now() - startTime;
    const progress = Math.min(elapsedTime / scrollDuration, 1);
    // Простая функция для сглаживания (без easing.js)
    const easedProgress = progress * progress * (3 - 2 * progress);
    currentScrollTop =
      currentScrollTop + (targetScrollTop - currentScrollTop) * easedProgress;
    scrollContainerRefCurrent.scrollTop = currentScrollTop;

    if (progress < 1) {
      requestAnimationFrame(animateScroll);
    }
  };

  requestAnimationFrame(animateScroll);
};

const IcYoutube = (props) => (
  <svg
    width={props.width || "20"}
    height={props.height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.5"
      y="4.16666"
      width="15"
      height="11.6667"
      rx="4"
      stroke="#98A2B3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33337 7.5L12.5 10L8.33337 12.5V7.5Z"
      stroke="#98A2B3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcYoutube;

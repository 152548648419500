import { memo } from "react";

const LOADER_COLOR = {
  white: "white",
  black: "var)(--c-primary-gray-950)",
  brand: "var(--c-brand-500)",
  gray: "var(--secondary-color-400)",
};
type TLoader = {
  color?: keyof typeof LOADER_COLOR;
};
const Loader = memo(({ color = "gray" }: TLoader) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    width="20"
    height="20"
    style={{
      shapeRendering: "auto",
      display: "block",
      background: "transparent",
    }}
  >
    <g>
      <circle
        strokeDasharray="188.49555921538757 64.83185307179586"
        r="40"
        strokeWidth="10"
        stroke={LOADER_COLOR[color]}
        fill="none"
        cy="50"
        cx="50"
      >
        <animateTransform
          keyTimes="0;1"
          values="0 50 50;360 50 50"
          dur="1.3157894736842106s"
          repeatCount="indefinite"
          type="rotate"
          attributeName="transform"
        ></animateTransform>
      </circle>
      <g></g>
    </g>
  </svg>
));
export default Loader;
// <!-- [ldio] generated by https://loading.io -->

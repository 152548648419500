import React, { useEffect, useState } from "react";
import s from "./AccessToModelsBanner.module.css";
import Button from "../Button/Button";
// import { ReactComponent as XClose } from "shared/assets/icons/x-close.svg";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import image_banner from "shared/assets/images/image_banner.webp";
import XClose from "shared/assets/icons/x-close";
import { useNavigate } from "react-router-dom";
import { BtnColor } from "../Button/types";
const AccessToModelsBanner = ({
  title,
  lsKey,
}: {
  title: string;
  lsKey: string;
}) => {
  const [showBanner, setShowBanner] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const noBannerOnPage = localStorage.getItem(lsKey);
    if (!noBannerOnPage) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, []);

  const hideBanner = () => {
    setShowBanner(false);
    localStorage.setItem(lsKey, "true");
  };
  return (
    showBanner && (
      <div className={s.bannerContainer}>
        <img className={s.bannerContainerImg} src={image_banner} alt="logo" />
        <div className={s.bannerContainerBlock}>
          <h1 className={s.bannerContainerBlockText}>
            Получите доступ <br />к 19 моделям генерации {title}
          </h1>
          <Button
            color={BtnColor.WHITE}
            className={s.bannerContainerBlockBtn}
            onClick={() => {
              navigate(RoutePath.TARIFFS);
              hideBanner();
            }}
          >
            Открыть доступ от 299 рублей
          </Button>
          <button className={s.bannerContainerBlockClose} onClick={hideBanner}>
            <XClose />
          </button>
        </div>
      </div>
    )
  );
};

export default AccessToModelsBanner;

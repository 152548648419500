import useHttp from "shared/api/services/api";
import { API_PREFIX } from "shared/api/constants/api";

export type TUpdateDocumentData = {
  name?: string;
  description?: string;
};
export default class documentsApi {
  static http = useHttp(API_PREFIX.TEXT_DOCUMENTS);

  static async getAllTextDocuments() {
    const { data } = await documentsApi.http.get("");
    return data;
  }

  static async getTextDocument(id: string) {
    const { data } = await documentsApi.http.get(`/${id}`);
    return data;
  }
  static async getDocumentBody(id: string, version_id?: string) {
    const { data } = await documentsApi.http.get(
      `/${id}/versions/${version_id}`
    );
    return data;
  }

  static async createDocument() {
    const { data } = await documentsApi.http.post(``, {
      name: "Новый документ",
      body: {
        template: [],
      },
    });
    return data;
  }

  static async duplicateDocument(doc_id: string) {
    const { data } = await documentsApi.http.post(`/copy/${doc_id}`);
    return data;
  }

  static async updateDocument(doc_id: string, newData: TUpdateDocumentData) {
    const { data } = await documentsApi.http.patch(`/${doc_id}`, newData);
    return data;
  }

  static async deleteDocument(doc_id: string) {
    const { data } = await documentsApi.http.delete(`/${doc_id}`);
    return data;
  }

  static async createNewVersion({
    doc_id,
    data,
  }: {
    doc_id: string;
    data: any;
  }): Promise<{
    created_at: string;
    document_id: string;
    id: string;
  }> {
    const { data: document } = await documentsApi.http.post(
      `/${doc_id}/versions`,
      {
        body: {
          template: data,
        },
      }
    );
    return document;
  }

  static async getAvatar({ doc_id, files }: { doc_id: string; files: File[] }) {
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));
    const { data } = await documentsApi.http.post(
      `/${doc_id}/avatar`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  }

  static async getFile(doc_id: string, path_to_file: string) {
    const { data } = await documentsApi.http.get(
      `${doc_id}/files/${path_to_file}`,
      {
        responseType: "blob",
      }
    );
    return data;
  }
  static async uploadFiles(doc_id: string, files: File[]) {
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));
    const { data } = await documentsApi.http.post(`${doc_id}/files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  }

  static async searchDocument(namePattern: string) {
    const { data } = await documentsApi.http.post(`/search/${namePattern}`, {
        pattern: namePattern
    });
    return data;
}
}

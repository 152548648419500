import { TStateSchema } from "app/providers/StoreProvider/config/types";

export const imagesSelectors = {
  generatedImagesList: (state: TStateSchema) =>
    state.aiImages.generatedImagesList,
  isLoading: (state: TStateSchema) => state.aiImages.isLoading,
  isError: (state: TStateSchema) => state.aiImages.isError,
  isMoreImages: (state: TStateSchema) => state.aiImages.isMoreImages,
  isGettingMore: (state: TStateSchema) => state.aiImages.isGettingMore,
};

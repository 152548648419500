import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "../../../../app/providers/ThemeProvider";
import s from "./Header.module.css";
import Button from "../../../../shared/ui/Button/Button";
import { BtnColor, BtnSize } from "../../../../shared/ui/Button/types";
import DarkTheme from "../../../../shared/assets/icons/dark";
import Lightning from "../../../../shared/assets/icons/lightning";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import { SearchBar } from "widgets/SearchBar";
import Menu01 from "shared/assets/icons/menu-01";
import HeaderAvatar from "shared/ui/HeaderAvatar/HeaderAvatar";
import { useAppDispatch } from "app/providers/StoreProvider/config/store";
import { userThunks } from "entities/User/model/services/thunks";
import WelcomeModal from "../../../../pages/content/Statistic/ui/WelcomeModal/WelcomeModal";
import Clock from "../../../../shared/assets/icons/clock";
import SpecialApi from "pages/content/Statistic/ui/WelcomeModal/SpecialApi";

interface THeaderProps {
  setSidebarOpen: (value: boolean) => void;
}

export const Header = ({ setSidebarOpen }: THeaderProps) => {
  const { toggleTheme } = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(userThunks.getUserAvatar());
  }, [dispatch]);

  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<string[] | null>(null);

  // Проверка: если промо уже завершено, не показываем ничего
  const [promoExpired, setPromoExpired] = useState(
    () => localStorage.getItem("promoExpired") === "true"
  );

  // Состояния модального окна и кнопки в хедере
  const [isWelcomeModalOpen, setWelcomeModalOpen] = useState(false);
  const [hasModalBeenShown, setHasModalBeenShown] = useState(false);
  const [showHeaderButton, setShowHeaderButton] = useState(false);

  // Проверка наличия ссылки перед открытием модального окна
  const checkForLink = async () => {
    try {
      const data = await SpecialApi.getSpecialOffers();
      return Array.isArray(data) && data.length > 0; // Возвращаем true, если ссылка есть
    } catch (error) {
      console.error("Ошибка при получении специальных предложений:", error);
      return false; // Возвращаем false при ошибке
    }
  };

  // Таймер предложения: 15 минут (15 * 60 сек)
  const [timeLeft, setTimeLeft] = useState(15 * 60);
  const [isTimerExpired, setIsTimerExpired] = useState(false);

  // Глобальный таймер – запускается, если акция ещё активна
  useEffect(() => {
    if (promoExpired) return;
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setIsTimerExpired(true);
          localStorage.setItem("promoExpired", "true");
          setPromoExpired(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [promoExpired]);

  // При первом входе открываем модалку через 15 секунд, если она ещё не была показана
  useEffect(() => {
    if (promoExpired) return;
    if (!hasModalBeenShown) {
      const modalTimer = setTimeout(async () => {
        const linkExists = await checkForLink(); // Проверяем наличие ссылки
        if (linkExists) {
          setWelcomeModalOpen(true); // Открываем модальное окно, если ссылка есть
        }
        setHasModalBeenShown(true); // Устанавливаем, что модалка была показана
      }, 15000);
      return () => clearTimeout(modalTimer);
    }
  }, [hasModalBeenShown, promoExpired]);

  const handleSearch = useCallback(() => {
    setSearchResults(["Документ 1", "Документ 2", "Документ 3"]);
  }, []);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  // Если промо завершено, не отображаем модалку и кнопку
  if (promoExpired) {
    return (
      <div className={s.header}>
        <div className="flex w-full items-center justify-between gap-3">
          <SearchBar
            className={s.searchBar}
            value={search}
            onChange={setSearch}
            handleSearch={handleSearch}
            searchResultsLength={searchResults ? searchResults.length : 0}
            loading={false}
            setSearchResults={setSearchResults}
            isSearchResults={Boolean(searchResults)}
            searchItems={(searchResults || []).map((item: string) => (
              <div key={item} className="p-3">
                {item}
              </div>
            ))}
            isDropDown
            disabled
          />
          <div className={s.buttonGroup}>
            <Button
              color={BtnColor.BLACK}
              size={BtnSize.XXSMALL}
              className={s.buttonPlane}
              to={RoutePath.TARIFFS}
            >
              <Lightning />
              Выбрать план
            </Button>
            <button onClick={toggleTheme} className={s.buttonTheme}>
              <DarkTheme />
            </button>
            <HeaderAvatar />
          </div>
          <Button
            color={BtnColor.CLEAR}
            size={BtnSize.XXSMALL}
            onClick={() => setSidebarOpen(true)}
            className={s.burgerMenu}
          >
            <Menu01 />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={s.header}>
      <div className="flex w-full items-center justify-between gap-3">
        <SearchBar
          className={s.searchBar}
          value={search}
          onChange={setSearch}
          handleSearch={handleSearch}
          searchResultsLength={searchResults ? searchResults.length : 0}
          loading={false}
          setSearchResults={setSearchResults}
          isSearchResults={Boolean(searchResults)}
          searchItems={(searchResults || []).map((item: string) => (
            <div key={item} className="p-3">
              {item}
            </div>
          ))}
          isDropDown
          disabled
        />

        {/* Кнопка с таймером: повторное открытие модального окна до истечения таймера */}
        {showHeaderButton && !isTimerExpired && !isWelcomeModalOpen && (
          <Button
            color={BtnColor.BLACK}
            size={BtnSize.XXSMALL}
            onClick={() => {
              setWelcomeModalOpen(true);
              setShowHeaderButton(false);
            }}
            className={`${s.openModalButton} ${s.timerButton}`}
          > 
           <p className={s.timerText}>Тариф «Профи» за 199 ₽</p>
            <span className={s.timerIcon}>
              <Clock /> {formatTime(timeLeft)}
            </span>
          </Button>
        )}

        <div className={s.buttonGroup}>
          <Button
            color={BtnColor.BLACK}
            size={BtnSize.XXSMALL}
            className={s.buttonPlane}
            to={RoutePath.TARIFFS}
          >
            <Lightning />
            Выбрать план
          </Button>
          <button onClick={toggleTheme} className={s.buttonTheme}>
            <DarkTheme />
          </button>
          <HeaderAvatar />
        </div>
        <Button
          color={BtnColor.CLEAR}
          size={BtnSize.XXSMALL}
          onClick={() => setSidebarOpen(true)}
          className={s.burgerMenu}
        >
          <Menu01 />
        </Button>
      </div>
      <WelcomeModal
        isOpen={isWelcomeModalOpen}
        onClose={() => {
          setWelcomeModalOpen(false);
          setShowHeaderButton(true);
        }}
        timeLeft={timeLeft}
        setTimeLeft={setTimeLeft}
      />
    </div>
  );
};

import cls from "./GptModels/GptModels.module.css"; // todo change
import Check from "shared/assets/icons/check";
import { useEffect, useRef, useState } from "react";
import { classNames } from "shared/lib/classNames/classNames";
import s from "./PopupSettingModel.module.css";
import IcPremiumToken from "shared/assets/icons/IcPremiumToken";
import Badge from "shared/ui/Badge/Badge";
import { BadgeColor } from "shared/ui/Badge/types";
import { useSelector } from "react-redux";
import { subscriptionsSelector } from "entities/UserSubPlan";
import { PLANS } from "pages/content/Tariffs";
import { TARIFFS_NAMES } from "shared/constants/common";
import IcLock03 from "shared/assets/icons/IcLock03";
import Button from "shared/ui/Button/Button";
import { BtnColor, BtnSize } from "shared/ui/Button/types";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "shared/config/routeConfig/routeConfig";

interface PopupSettingModelProps {
  selectedModel: any;
  onChangeGptModel: (model: any) => void;
  gptModels: {
    [key in string]: {
      [key in string]: {
        tokens_usage: number;
        description: string;
        usage: string[]; //"special" | "fast" | "high-quality"
        isNew?: boolean;
        from?: boolean;
      };
    };
  };
  modelsIcons: {
    [key in string]: string;
  };
}

const toggles = {
  all: {
    title: "Все",
  },

  fast: {
    title: "Быстрые",
  },
  "high-quality": {
    title: "Высокое качество",
  },
  special: {
    title: "Специальные",
  },
} as const;

// todo create just one component
export const PopupSettingModel = ({
  selectedModel,
  onChangeGptModel,
  gptModels,
  modelsIcons,
}: PopupSettingModelProps) => {
  const [activeToggle, setActiveToggle] = useState("all");

  const modalScrollRef = useRef<null | any>(null);

  const userMaxPlan = useSelector(subscriptionsSelector.userSubPlan);

  const userPlanName = userMaxPlan?.maxUserPlan.title.split(" ")[0];

  const navigate = useNavigate();
  const planIsFree = userPlanName === TARIFFS_NAMES.FREE;

  useEffect(() => {
    const onContainerScroll = () => {
      const modalHeader = document.querySelector(`.${cls.modalHeader}`) as any;
      if (modalHeader && modalScrollRef?.current.scrollTop > 0) {
        modalHeader.style.borderBottom =
          "1px solid var(--primary-border-color)";
        modalHeader.style.boxShadow = "0px 1px 6px 0px #0A0A0A0F";
      } else {
        modalHeader.style.borderBottom = "none";
        modalHeader.style.boxShadow = "none";
      }
    };
    modalScrollRef?.current?.addEventListener("scroll", onContainerScroll);
    return () => {
      modalScrollRef?.current?.removeEventListener("scroll", onContainerScroll);
    };
  });

  return (
    <div className={s.popupContainer} onClick={(e) => e.stopPropagation()}>
      <div className={cls.models} ref={modalScrollRef}>
        <div className={cls.modalHeader}>
          <p className={cls.workType}>Тип работы</p>
          <div className={s.buttonContainer}>
            {Object.entries(toggles).map(([key, data]) => (
              <button
                key={key}
                onClick={() => setActiveToggle(key)}
                className={classNames(cls.toggle, {
                  [cls.activeToggle]: activeToggle === key,
                })}
              >
                <span>{data.title}</span>
              </button>
            ))}
          </div>
        </div>
        {Object.entries(gptModels).map(([familyName, data]) => {
          const modelsData = Object.entries(data);
          const noAccess = planIsFree && familyName !== "Stable Diffusion";
          if (activeToggle !== "all") {
            const foundedAccessibleModel = Object.values(data).some((model) =>
              model.usage.some((typeUsage: any) => typeUsage === activeToggle)
            );

            if (!foundedAccessibleModel) return null;
          }
          return (
            <div
              className={classNames(cls.gptFamily, {
                [cls.noAccess]: noAccess,
              })}
            >
              <div className="flex items-center">
                <div className={cls.gptFamilyName}>
                  <p>{familyName}</p>
                  {noAccess && (
                    <>
                      <IcLock03 />
                      <p>доступно с тарифа</p>{" "}
                      <span className={cls.tariffName}>
                        Старт
                        {/* {moreExpensiveModels[userPlanName!]} */}
                      </span>
                      <Button
                        color={BtnColor.GRADIENT}
                        size={BtnSize.XXSMALL}
                        className="ml-auto "
                        animated={false}
                        onClick={() => {
                          navigate(RoutePath.TARIFFS);
                        }}
                      >
                        Обновить
                      </Button>
                    </>
                  )}
                </div>
              </div>
              {modelsData.map(
                ([modelName, modelInfo]) =>
                  (activeToggle === "all" ||
                    //  @ts-ignore
                    modelInfo.usage.includes(activeToggle)) && (
                    <button
                      className={classNames(cls.gptFamilyModel, {
                        [cls.activeGptModel]: selectedModel === modelName,
                      })}
                      onClick={() => {
                        if (selectedModel === modelName || noAccess) return;
                        onChangeGptModel({
                          familyName,
                          modelName,
                          tokens_usage: modelInfo.tokens_usage,
                        });
                      }}
                    >
                      {/* @ts-ignore */}
                      <img src={modelsIcons[familyName]} alt="icon" />
                      <div className="flex justify-between gap-2">
                        <div>
                          <div className="flex items-center gap-1.5">
                            <p className="text-[14px]">{modelName}</p>
                            {modelInfo.isNew && (
                              <Badge color={BadgeColor.BLUE}>NEW</Badge>
                            )}
                          </div>
                          <p className={cls.modelDescription}>
                            {modelInfo.description}
                          </p>
                        </div>
                        <div>
                          <p className={cls.tokens}>
                            {selectedModel === modelName && (
                              <Check stroke="var(--c-brand-500)" />
                            )}
                            {modelInfo.from && "от"}
                            <IcPremiumToken />
                            {modelInfo.tokens_usage}
                          </p>
                        </div>
                      </div>
                    </button>
                  )
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

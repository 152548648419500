import { createSlice } from "@reduxjs/toolkit";
import { TImagesSchema } from "./types";
import { aiImagesThunks } from "../services/thunks";

const initialState: TImagesSchema = {
  generatedImagesList: [],
  offset: 0,
  isLoading: false,
  isError: null,
  isMoreImages: true,
  isGettingMore: false,
};

const imagesSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsError(state, action) {
      state.isError = action.payload;
    },
    setIsGettingMore(state, action) {
      state.isGettingMore = action.payload;
    },
    setIsMoreImages(state, action) {
      state.isMoreImages = action.payload;
    },
    setAllImages(state, action) {
      state.generatedImagesList = action.payload;
    },
    setOffset(state, { payload }) {
      state.offset = payload;
    },
    updateImages(state, { payload }) {
      if (payload.error) {
        state.generatedImagesList = state.generatedImagesList.filter(
          (_, i) => i >= payload.imagesLoadersCount
        );
      } else if (payload.isLoaders) {
        state.generatedImagesList = [
          ...payload.images,
          ...state.generatedImagesList,
        ];
      } else {
        // Находим индекс первого элемента, который не является загрузчиком
        const firstNonLoaderIndex = state.generatedImagesList.findIndex(
          // @ts-ignore
          (image) => !image.isLoader
        );

        // Если не нашли, значит все элементы - загрузчики и добавляем изображения в самый конец
        const startIndex =
          firstNonLoaderIndex === -1
            ? state.generatedImagesList.length - 1
            : firstNonLoaderIndex - 1;

        payload.images.reverse().forEach((image: any, index: number) => {
          // Обновляем только пока не выйдем за пределы массива
          if (startIndex - index >= 0) {
            state.generatedImagesList[startIndex - index] = image;
          }
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        aiImagesThunks.getGeneratedImagesList.fulfilled,
        (state, { payload }) => {
          if (payload)
            state.generatedImagesList = [
              ...state.generatedImagesList,
              ...payload.imagesPortion,
            ];
        }
      )
      .addCase(aiImagesThunks.deleteImage.fulfilled, (state, { payload }) => {
        if (payload) {
          state.generatedImagesList = state.generatedImagesList.filter(
            (image) => image.id !== payload.id
          );
        }
      })
      .addCase(
        aiImagesThunks.getImageFullUrl.fulfilled,
        (state, { payload }) => {
          if (payload) {
            const img = state.generatedImagesList.find(
              (img) => img.id === payload.id
            );
            if (img) {
              img.fullImageUrl = payload.fullImageUrl;
            }
          }
        }
      );
  },
});

export const imagesActions = imagesSlice.actions;
export const imagesReducer = imagesSlice.reducer;

import axios from "axios";
import { API_PREFIX, API_URL, VERSION } from "../constants/api";
import {
  LS_ACCESS_TOKEN,
  LS_NO_IMAGE_BANNER_ON_PAGE,
  LS_NO_VIDEO_BANNER_ON_PAGE,
} from "../constants/localStorage";
import { RoutePath } from "shared/config/routeConfig/routeConfig";

export const refresh = async () => {
  const { data } = await axios.post(
    `${API_URL}/${VERSION}/${API_PREFIX.AUTH}/refresh`,
    null,
    {
      withCredentials: true,
    }
  );
  return data;
};
export const refreshToken = async () => {
  try {
    // update access token
    const data = await refresh();
    localStorage.setItem(LS_ACCESS_TOKEN, data.access_token); // Сохранение нового access token

    return data.access_token;
  } catch (error) {
    // console.log(error);
    // todo or create clearLSFunction
    localStorage.removeItem("access_token");

    if (window.location.pathname !== RoutePath.LOGIN) {
      window.location.assign(RoutePath.LOGIN);
      // localStorage.removeItem(LS_NO_IMAGE_BANNER_ON_PAGE);
      // localStorage.removeItem(LS_NO_VIDEO_BANNER_ON_PAGE);

      localStorage.clear();
      // window.location.reload();
    }
  }
};

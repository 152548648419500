import cn from "./SupportDetail.module.scss";
import PageTitle from "../../../../../shared/ui/PageTitle/PageTitle";
import { classNames } from "../../../../../shared/lib/classNames/classNames";
import React, { useEffect, useState } from "react";
import { HelperChat } from "./HelperChat/HelperChat";
import { BtnColor, BtnSize } from "../../../../../shared/ui/Button/types";
import Button from "shared/ui/Button/Button";
import { IcBasket } from "../../../../../shared/assets/icons/IcBasket";
import { ModalOverlay } from "../../../../../shared/ui/ModalOverlay/ModalOverlay";
import { ToBack } from "../../../../../shared/ui/ToBack/ToBack";

import { useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../app/providers/StoreProvider/config/store";
import { supportThunks } from "../../model/slice/thunks";
import { supportSelectors } from "../../model/slice/selectors/supportSelectors";
import { useSelector } from "react-redux";
import { IcClose } from "../../../../../shared/assets/icons/IcClose";
import ChevronRight from "shared/assets/icons/chevron-right";
import ChevronLeft from "shared/assets/icons/chevron-left";
import { userApi } from "entities/User";
import { userSelector } from "entities/User/model/selectors";

const data: any = {
  support_id: "RUXAdIS02330",
  status: "Отвечено",
  category: "Общий запрос",
  theme: "Chat",
};

export const SupportDetail = () => {
  const classNamesDet = {
    container: classNames(cn.container),
    flexContainer: classNames(cn.flexContainer),
    descriptionBlock: classNames(cn.descriptionBlock),
    chatBlock: classNames(cn.chatBlock),
    blockDescr: classNames(cn.blockDescr),
    textHeader: classNames(cn.textHeader),
    textDescr: classNames(cn.textDescr),
    delimiter: classNames(cn.delimiter),
    btnReqRes: classNames(cn.btnReqRes),
    modalReq: classNames(cn.modalReq),
    headerModal: classNames(cn.headerModal),
    textModalHeader: classNames(cn.textModalHeader),
    modalBtn: classNames(cn.modalBtn),
    modalContent: classNames(cn.modalContent),
    textContent: classNames(cn.textContent),
    textContentDescr: classNames(cn.textContentDescr),
    modalBtnContainer: classNames(cn.modalBtnContainer),
    btnModalArchive: classNames(cn.btnModalArchive),
    btnModalCancel: classNames(cn.btnModalCancel),
  };

  const [isOpenModalResponse, setIsOpenModalResponse] = React.useState(false);
  const [detailsOpened, setDetailsOpened] = React.useState(false);
  const dispatch = useAppDispatch();
  const [userEmail, setUserEmail] = useState("");
  const isAdmin =
    useAppSelector(userSelector.currentUser)?.is_superuser || false;

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(supportThunks.getCurrentRequestAT({ id: id }));
    }
  }, [dispatch, id]);

  const currentRequest = useSelector(supportSelectors.getCurrentRequest);
  const navigate = useNavigate();
  const onClickOpenModalReqRes = () => {
    setIsOpenModalResponse(!isOpenModalResponse);
  };
  const onClickOpenModalReqResOverlay = () => {
    setIsOpenModalResponse(false);
  };

  const onClickSendReqInArchive = () => {
    if (id) {
      dispatch(supportThunks.sendRequestInArchiveAT({ id: id }));

      onClickOpenModalReqResOverlay();
    }
  };

  useEffect(() => {
    const btn = document.querySelector("#details-btn");
    if (window.innerWidth < 1060 && btn) {
      btn?.scrollIntoView();
    }
  }, []);

  const fetchUserByID = async () => {
    if (currentRequest?.user_id && isAdmin === true) {
      const data: any = await userApi.getUserById(currentRequest?.user_id);
      setUserEmail(data.email);
    }
  };

  useEffect(() => {
    fetchUserByID();
  }, [currentRequest?.user_id]);

  return (
    <>
      <div className={cn.goBackBtn}>
        <ToBack title={"Вернуться к запросам"} />
      </div>
      <PageTitle
        description={`${data.support_id}`}
        title="Чат обращения"
        button={
          window.innerWidth > 1060 ? (
            <></>
          ) : (
            <Button
              onClick={() => setDetailsOpened(!detailsOpened)}
              id="details-btn"
            >
              {detailsOpened ? (
                <>
                  <ChevronLeft /> Вернуться к чату
                </>
              ) : (
                <>
                  Перейти к просмотру деталей <ChevronRight />
                </>
              )}
            </Button>
          )
        }
      />
      <div className={classNamesDet.container}>
        <div className={classNamesDet.flexContainer}>
          <div
            className={classNames(classNamesDet.descriptionBlock, {
              [cn.opened]: detailsOpened,
            })}
          >
            <div>
              <div className={classNamesDet.blockDescr}>
                <p
                  style={{ fontWeight: 700 }}
                  className={classNamesDet.textHeader}
                >
                  ID обращения
                </p>
                <p className={classNamesDet.textDescr}>
                  {currentRequest?.id.slice(0, 8)}
                </p>
                <div className={classNamesDet.delimiter}></div>
              </div>
              <div className={classNamesDet.blockDescr}>
                <p className={classNamesDet.textHeader}>Статус</p>
                <p className={classNamesDet.textDescr}>
                  {currentRequest?.status}
                </p>
                <div className={classNamesDet.delimiter}></div>
              </div>
              <div className={classNamesDet.blockDescr}>
                <p className={classNamesDet.textHeader}>Категория</p>
                <p className={classNamesDet.textDescr}>
                  {currentRequest?.topic}
                </p>
                <div className={classNamesDet.delimiter}></div>
              </div>
              <div className={classNamesDet.blockDescr}>
                <p className={classNamesDet.textHeader}>Приоритет</p>
                <p className={classNamesDet.textDescr}>
                  {currentRequest?.urgency}
                </p>
                <div className={classNamesDet.delimiter}></div>
              </div>
              {isAdmin && (
                <div className={classNamesDet.blockDescr}>
                  <p className={classNamesDet.textHeader}>Email пользователя</p>
                  <p className={classNamesDet.textDescr}>{userEmail}</p>
                  <div className={classNamesDet.delimiter}></div>
                </div>
              )}
              <div className={cn.mobileBtnGroup}>
                <Button
                  color={BtnColor.BLACK}
                  isFull
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeft />
                  Вернуться к запросам
                </Button>
                <Button
                  color={BtnColor.GRAY}
                  isFull
                  disabled={currentRequest?.status === "Вопрос решен"}
                  onClick={onClickOpenModalReqRes}
                >
                  <IcBasket />
                  Пометить вопрос решенным
                </Button>
              </div>
            </div>
            <Button
              disabled={currentRequest?.status === "Вопрос решен"}
              onClick={onClickOpenModalReqRes}
              className={classNamesDet.btnReqRes}
              size={BtnSize.MEDIUM}
            >
              <IcBasket /> Вопрос решен
            </Button>
          </div>
          <div className={classNamesDet.chatBlock}>
            <HelperChat
              chatId={currentRequest?.id}
              status={currentRequest && currentRequest?.status}
            />
          </div>
        </div>
      </div>
      <ModalOverlay
        open={isOpenModalResponse}
        onClose={onClickOpenModalReqResOverlay}
      >
        <div className={classNamesDet.modalReq}>
          <div className={classNamesDet.headerModal}>
            <h2 className={classNamesDet.textModalHeader}>Вопрос решен</h2>
            <div
              style={{ cursor: "pointer" }}
              onClick={onClickOpenModalReqResOverlay}
            >
              <IcClose />
            </div>
          </div>
          <div className={classNamesDet.modalContent}>
            <div style={{ marginBottom: "5px" }}>
              <p className={classNamesDet.textContent}>
                Перенести обращение в архив?
              </p>
            </div>
            <div>
              <p className={classNamesDet.textContentDescr}>
                Обращение можно будет восстановить.
              </p>
            </div>
          </div>
          <div className={classNamesDet.modalBtnContainer}>
            <Button
              color={BtnColor.WHITE}
              className={classNamesDet.btnModalCancel}
              onClick={onClickOpenModalReqResOverlay}
            >
              Отменить
            </Button>
            <Button
              color={BtnColor.BLACK}
              className={classNamesDet.btnModalArchive}
              onClick={onClickSendReqInArchive}
            >
              Архивировать
            </Button>
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};

export enum INPUT_TYPES {
  EMAIL = "email",
  PASSWORD = "password",
  TEXT = "text",
  TEL = "tel",
  NUMBER = "number",
}
// type?: (typeof INPUT_TYPES)[keyof typeof INPUT_TYPES];

export enum INPUT_SIZES {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum INPUT_COLORS {
  WHITE = "white",
  GRAY = "gray",
  DARK_GRAY = "dark_gray",
}
// extends React.InputHTMLAttributes<HTMLInputElement>
export interface TInput {
  id: string;
  placeholder: string;
  value: string;
  onChange:
    | React.Dispatch<React.SetStateAction<string>>
    | ((value: string) => void);
  type?: (typeof INPUT_TYPES)[keyof typeof INPUT_TYPES];
  invalidText?: string;
  invalid?: boolean;
  onBlur?: () => void;
  label?: string;
  autocomplete?: "off" | "on" | string;
  size?: INPUT_SIZES;
  description?: string;
  squared?: boolean;
  disabled?: boolean;
  className?: string;
  color?: INPUT_COLORS;
  smallGap?:boolean;
}

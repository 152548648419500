import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../Button/Button";
import { BtnColor, BtnSize } from "../Button/types";
import AudioRecorder from "pages/content/ChatBot/ui/MessageToolbar/ui/AudioRecorder/AudioRecorder";
import { Dice } from "shared/assets/icons/dice-3";
import Trash01 from "shared/assets/icons/trash01";
import Stop from "shared/assets/icons/stop";
import { classNames } from "shared/lib/classNames/classNames";
import s from "./GeneratingTextarea.module.scss";

import { API_PREFIX, API_URL, VERSION } from "shared/api/constants/api";
import { streamData } from "pages/content/ChatBot/lib/streamData";
import { scrollToBottom } from "pages/content/ChatBot/lib/scrollToBottom";
import { useDispatch } from "react-redux";
import { userSubPlanActions } from "entities/UserSubPlan";
import Unexpanded from "shared/assets/icons/unexpanded";
import { Expand } from "shared/assets/icons/expand-01";
export const TEXTAREA_HEIGHT = 78;
export const TEXTAREA_MAX_HEIGHT = 222;
export const MAX_TEXT_PROMPT_LENGTH = 2000;
const currentTextGptModel = "gpt-4o-mini";

export const getEnhancePrompt = (promptText: string) =>
  `Исходный промпт: ${promptText}. Возьми следующий промпт и улучши его, добавив больше деталей, фон, стиль и дополнительные характеристики. Сделай его более визуально насыщенным и интересным. Добавь: Подробности об объекте (например, эмоции, поза, одежда, выражение лица).Фон (например, звёзды, планеты, инопланетные существа, ракета). Дополнительные элементы (например, освещение, перспективу, атмосферу). Не добавляй комментарии или пояснения — просто выдай улучшенный промпт. Ответь текстом. Про стиль ничего не добавляй. Ответь на русском языке.`;

export const randomPromptDescription = (type: "video" | "image") =>
  `Сгенерируй случайный, креативный и забавный промпт для генерации ${type === "video" ? "видео" : "изображения"}. Промпт должен описывать необычную сцену, сочетая неожиданные элементы, персонажей или стили. Например, ‘Шрек гуляет в скафандре по Луне’ или ‘Викторианские роботы устраивают чаепитие под водой’. Не добавляй комментарии или пояснения.`;

const GeneratingTextarea = ({
  setPromptText,
  promptText,
  placeholder = "Enter prompt",
  type,
}: {
  setPromptText: React.Dispatch<React.SetStateAction<string>>;
  promptText: string;
  placeholder: string;
  type: "video" | "image";
}) => {
  const [isTextareaExpanded, setIsTextareaExpanded] = useState(false);
  const [isUpdatingPrompt, setIsUpdatingPrompt] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const isCancelledRef = useRef(false); // Создаем реф для состояния отмены
  const [isVoiceTranscripting, setIsVoiceTranscriting] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      setIsVoiceTranscriting(true);
    }
  };

  const cancelRecording = () => {
    isCancelledRef.current = true;

    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };
  let curr = "";
  // todo check this method
  const handleNewPartialMessage = useCallback(
    (partialText: string, type?: "text" | "tokens_usage") => {
      if (scrollRef.current) scrollToBottom(scrollRef.current);

      if (type === "tokens_usage") {
        dispatch(
          userSubPlanActions.changeTokensCount({
            used_tokens: Number(partialText),
          })
        );
        return;
      }
      curr = curr + partialText;
    },
    []
  );
  const onStreamClose = useCallback(() => {
    if (curr) {
      setPromptText(curr);
    }
    curr = "";
  }, []);
  const generateText = async (comment: string, request?: string) => {
    setPromptText("");
    setIsUpdatingPrompt(true);
    try {
      const messageFetchData = {
        url: `${API_URL}/${VERSION}/${API_PREFIX.AI}/editor?model=${currentTextGptModel}`,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          content: request ? request + " " + comment : comment,
          role: "user",
        }),
      };
      await streamData(
        messageFetchData,
        handleNewPartialMessage,
        onStreamClose,
        setIsUpdatingPrompt
      );
    } catch (e: any) {
      console.log(e);
    } finally {
      setIsUpdatingPrompt(false);
    }
  };
  const handleChangePromptText = (e: any) => {
    const text = e.target.value;

    if (text.length <= MAX_TEXT_PROMPT_LENGTH) {
      setPromptText(text);
    }
  };
  // TODO create function with heights in params
  useEffect(() => {
    const textarea = textareaRef.current;
    if (isTextareaExpanded && textarea) {
      textarea.style.height = "100%";
    } else {
      if (textarea) {
        textarea.style.height = TEXTAREA_HEIGHT + "px"; // Сброс высоты
        const height = textarea.scrollHeight + 5; // Высота контента
        if (height >= TEXTAREA_HEIGHT) {
          textarea.style.height = Math.min(height, TEXTAREA_MAX_HEIGHT) + "px"; // Установка высоты, не превышающей maxHeight
        }
      }
      if (
        !promptText &&
        textarea &&
        textarea?.style.height !== TEXTAREA_HEIGHT + "px"
      ) {
        textarea.style.height = TEXTAREA_HEIGHT + "px";
      }
    }
  }, [promptText, isTextareaExpanded, textareaRef.current]);

  // todo ??????
  // useEffect(() => {
  //   if (promptText.length > MAX_TEXT_PROMPT_LENGTH) {
  //     setPromptText(promptText.slice(0, MAX_TEXT_PROMPT_LENGTH));
  //   }
  // }, [promptText]);
  return (
    <div
      className={classNames("", {
        [s.expandedTextareaOverlay]: isTextareaExpanded,
      })}
      role="button"
      onClick={() => isTextareaExpanded && setIsTextareaExpanded(false)}
    >
      <div className={s.sidebarBoxInput} onClick={(e) => e.stopPropagation()}>
        <div className={s.textareaContainer}>
          {isUpdatingPrompt ? (
            <p className={s.botTextMessage}>Создаю промпт...</p>
          ) : isRecording ? (
            <p className={s.botTextMessage}>Говорите, я вас слушаю...</p>
          ) : isVoiceTranscripting ? (
            <p className={s.botTextMessage}>Расшифровываю аудио...</p>
          ) : (
            <>
              <textarea
                ref={textareaRef}
                placeholder={placeholder}
                value={promptText}
                onChange={handleChangePromptText}
                className={s.textareaPrompt}
              />
            </>
          )}
        </div>
        {!isUpdatingPrompt &&
          !isRecording &&
          !isVoiceTranscripting &&
          promptText.length >= MAX_TEXT_PROMPT_LENGTH - 100 && (
            <p className="text-xs text-red-400 px-3 py-1.5">
              Осталось {MAX_TEXT_PROMPT_LENGTH - promptText.length} символов
            </p>
          )}
        {!isUpdatingPrompt && (
          <div className={s.sidebarBoxBtn}>
            {isRecording ? (
              <div className="flex items-center w-full gap-2 justify-end">
                <button onClick={cancelRecording}>
                  <Trash01 />
                </button>
                <button className={s.sidebarBtnBarBtn} onClick={stopRecording}>
                  <Stop fill="#98A2B3" />
                </button>
              </div>
            ) : (
              <>
                <Button
                  onClick={() => generateText(getEnhancePrompt(promptText))}
                  size={BtnSize.MICRO}
                  color={BtnColor.WHITE}
                  className={"!shadow-none"}
                  animated={false}
                  disabled={!promptText}
                >
                  Улучшить промпт
                </Button>
                <div className={s.sidebarBtnBar}>
                  <button
                    className={s.sidebarBtnBarBtn}
                    onClick={() => setIsTextareaExpanded((p) => !p)}
                  >
                    {isTextareaExpanded ? <Unexpanded /> : <Expand />}
                  </button>
                  <button
                    className={s.sidebarBtnBarBtn}
                    onClick={() => generateText(randomPromptDescription(type))}
                  >
                    <Dice />
                  </button>

                  <AudioRecorder
                    setIsRecording={setIsRecording}
                    setMediaRecorder={setMediaRecorder}
                    setIsVoiceTranscripting={setIsVoiceTranscriting}
                    setUserRequest={setPromptText}
                    isCancelledRef={isCancelledRef}
                    className={s.sidebarBtnBarBtn}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneratingTextarea;

const dateOptions = {
  year: "numeric",
  day: "numeric",
  // hour: "2-digit",
  // minute: "2-digit",
  // second: "2-digit",
  // timeZoneName: "short",
} as const;

export const dateTimeFormat = (dateString: string, monthLength = "long") => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("ru-RU", {
    ...dateOptions,
    month: monthLength as Intl.DateTimeFormatOptions["month"],
  }).format(date);
};

//TODO CHECK AND ADD!
// const dateOptions = {
//   year: "numeric",
//   month: "long",
//   day: "numeric",
// } as const;
// const timeOptions = {
//   hour: "2-digit",
//   minute: "2-digit",
//   second: "2-digit",
//   // timeZoneName: "short",
// };
// export const dateTimeFormat = (
//   dateString: string,
//   isTimeShown = false,
// monthLength = "long",
//   gmtOffset = "0"
// ) => {
//   // Создаем дату на основе серверного времени и смещения в UTC
//   const serverDate = new Date(dateString + "Z"); // Добавляем Z для указания на UTC
//   const offsetHours = parseInt(gmtOffset, 10); // Преобразуем смещение в число

//   // Переводим серверное время в миллисекундах и добавляем смещение
//   const utcTimeWithOffset = serverDate.getTime() + offsetHours * 60 * 60 * 1000;

//   // Создаем новую дату с учетом смещения
//   const userTime = new Date(utcTimeWithOffset);

//   // Определяем временную зону пользователя
//   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//   // Форматируем дату в локальной временной зоне пользователя
//   const time = isTimeShown ? timeOptions : {};
//   return userTime.toLocaleString("ru-RU", {
//     ...dateOptions,
//     ...time,
//     timeZone: userTimeZone, // Используем временную зону пользователя
//   });
// };

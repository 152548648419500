import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminApi } from "../../api/AdminApi";

export const getDefaultSettings = createAsyncThunk(
  "defaultSettings/getDefaultSettings",
  async ({ user_id }: { user_id: string }, { dispatch }) => {
    // dispatch(userActions.isError(null));
    try {
      const settings = await AdminApi.getDefaultUserSettings(user_id);
      return settings;
    } catch (e: any) {
      // dispatch(userActions.isError(e));
    }
  }
);

const Microphone01 = ({ size }) => (
  <svg
    width={size || "20"}
    height={size || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8332 8.33342V10.0001C15.8332 13.2217 13.2215 15.8334 9.99984 15.8334M4.1665 8.33342V10.0001C4.1665 13.2217 6.77818 15.8334 9.99984 15.8334M9.99984 15.8334V18.3334M6.6665 18.3334H13.3332M9.99984 12.5001C8.61913 12.5001 7.49984 11.3808 7.49984 10.0001V4.16675C7.49984 2.78604 8.61913 1.66675 9.99984 1.66675C11.3805 1.66675 12.4998 2.78604 12.4998 4.16675V10.0001C12.4998 11.3808 11.3805 12.5001 9.99984 12.5001Z"
      stroke="#98A2B3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Microphone01;

import { RoutePath } from "shared/config/routeConfig/routeConfig";
import img_generation from "shared/assets/images/image_generation.webp";
import dalle from "shared/assets/icons/OpenAI_Logo.svg";
export const BtnVideoLinks = [
  {
    id: 1,
    title: "Текст в видео",
    description: "Сгенерируйте любую свою фантазию в формате JPG или PNG",
    img: img_generation,
    link: RoutePath.GENERATION_VIDEO + "?text_to_video=true",
  },
  {
    id: 2,
    title: "Изображение в видео",
    description: "Легко замените фон ваших изображений",
    img: img_generation,
    link: RoutePath.GENERATION_VIDEO + "?image_to_video=true",
    notFree: true,
  },
  {
    id: 3,
    title: "Креативные эффекты",
    description: "Удалите фон из изображения с помощью специальных алгоритмов",
    img: img_generation,
    link: "",
    disabled: true,
  },
];

export const VIDEO_GPT_MODELS = {
  PixVerse: {
    "PixVerse V3.5": {
      tokens_usage: 1,
      description: "Идеальная модель для генерации творческих видео",
      usage: ["special"],
      isNew: true,
    },
  },
};

export const VIDEO_MODELS_ICONS = {
  PixVerse: dalle,
};

export const VIDEO_MODELS_ASPECT_RATIO = {
  "PixVerse V3.5": ["1:1", "3:4", "4:3", "9:16", "16:9"],
};
export const VIDEO_MODELS_QUALITY = {
  "PixVerse V3.5": ["360p", "540p", "720p", "1080p"],
};

export const VIDEO_MODELS_DURATION = {
  "PixVerse V3.5": {
    normal: [5, 8],
    fast: [5],
  },
};
export const VIDEO_MODELS_PRICE = {
  "PixVerse V3.5": {
    "360p normal 5": 45,
    "360p fast 5": 90,
    "360p normal 8": 90,
    "540p normal 5": 45,
    "540p fast 5": 90,
    "540p normal 8": 90,
    "720p normal 5": 60,
    "720p fast 5": 120,
    "720p normal 8": 120,
    "1080p normal 5": 120,
  },
};
export const VIDEO_MODELS_URLS = {
  "PixVerse V3.5": "pixverse",
};

const LogoShort = (props) => (
  <svg
    width="41"
    height="24"
    viewBox="0 0 42 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3654_34228)">
      <path
        d="M25.6558 8.93048C23.94 2.00955 20.6965 0.000244141 15.1041 0.000244141C9.51176 0.000244141 6.30399 2.00955 4.55246 8.93048C2.80094 15.8514 0.787689 24.0002 0.787689 24.0002H6.82743C6.82743 24.0002 10.1068 10.9398 10.5922 8.93048C11.0776 6.92117 12.3079 2.00955 15.1041 2.00955C17.9003 2.00955 19.0948 6.92117 19.616 8.93048C20.1372 10.9398 23.3808 24.0002 23.3808 24.0002H29.4205C29.4205 24.0002 27.3715 15.8514 25.6558 8.93048Z"
        fill="#2871FF"
      />
      <path
        d="M41.4999 0.000244141H35.4602V24.0002H41.4999V0.000244141Z"
        fill="#2871FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_3654_34228">
        <rect
          width="41"
          height="24.0002"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LogoShort;

import { API_PREFIX } from "../constants/api";
import useHttp from "./api";

export default class aiApi {
  static http = useHttp(API_PREFIX.AI);
  static httpvideo = useHttp(API_PREFIX.AI_VIDEO);

  static async generateText(text: string) {
    const { data } = await aiApi.http.post("/editor", {
      role: "user",
      content: text,
    });
    return data;
  }
  static async voiceToText(voice: any) {
    const { data } = await aiApi.http.post(
      "/voice_to_text",
      {
        voice,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  }

  static async generateImage(url: string, data: any) {
    const response = await aiApi.http.post(url, data);
    return response.data;
  }
  static async generateVideo(url: string, data: any, headers: any = {}) {
    const response = await aiApi.http.post(url, data, headers);
    return response.data;
  }
  static async getgenerateVideo(url: string) {
    const response = await aiApi.httpvideo.get(url);
    return response.data;
  }
}

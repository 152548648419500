import { createAsyncThunk } from "@reduxjs/toolkit";
import ImageApi from "../api/imagesAPI";
import { imagesActions } from "../../slice";

export const deleteImage = createAsyncThunk(
  "aiImages/deleteImage",
  async ({ id }: { id: string }, { rejectWithValue, dispatch }) => {
    dispatch(imagesActions.setIsError(null));
    try {
      const res = await ImageApi.deleteImage(id);
      return {
        deletedImage: res,
        id,
      };
    } catch (e) {
      dispatch(imagesActions.setIsError(e));
      return rejectWithValue(e);
    }
  }
);

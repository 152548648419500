import { createAsyncThunk } from "@reduxjs/toolkit";
import { videosActions } from "../slice";
import VideoApi from "../services/api/videoAPI";

const VIDEOS_PORTION = 24;

export const getGeneratedVideosList = createAsyncThunk(
  "aiVideos/getGeneratedVideosList",
  async (_, thunkAPI) => {
    const { rejectWithValue, dispatch, getState } = thunkAPI;

    // @ts-ignore
    const { generatedVideosList, offset } = getState().aiVideos;
    try {
      if (!generatedVideosList.length) {
        dispatch(videosActions.setIsLoading(true));
      } else {
        dispatch(videosActions.setIsGettingMore(true));
      }

      const res = await VideoApi.getAllGeneratedVideos({ offset });

      dispatch(videosActions.setOffset(offset + VIDEOS_PORTION));

      if (VIDEOS_PORTION > res.length) {
        dispatch(videosActions.setIsMoreVideos(false));
      }
      return { videosPortion: res };
    } catch (e) {
      dispatch(videosActions.setIsError(e));
      rejectWithValue(e);
    } finally {
      dispatch(videosActions.setIsLoading(false));
      dispatch(videosActions.setIsGettingMore(false));
    }
  }
);

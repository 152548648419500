import { FC, useEffect, useState } from 'react';
import IcPremiumToken from 'shared/assets/icons/IcPremiumToken';
import IcToken from 'shared/assets/icons/IcToken';
import s from './WelcomeModal.module.css';
import RArrow from 'shared/assets/icons/RArrow';
import SpecialApi from './SpecialApi';

interface WelcomeModalProps {
    isOpen: boolean;
    onClose: () => void;
    timeLeft: number;
    setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
}

const WelcomeModal: FC<WelcomeModalProps> = ({ isOpen, onClose, timeLeft, setTimeLeft }) => {
    const [hasLink, setHasLink] = useState(false);

    useEffect(() => {
        const checkForLink = async () => {
            try {
                const data = await SpecialApi.getSpecialOffers();
                if (Array.isArray(data) && data.length > 0) {
                    setHasLink(true);
                } else {
                    setHasLink(false);
                }
            } catch (error) {
                console.error("Ошибка при получении специальных предложений:", error);
                setHasLink(false);
            }
        };

        checkForLink();
    }, []);

    useEffect(() => {
        if (!isOpen) return;

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    onClose();
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [isOpen, setTimeLeft, onClose]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const handleSelectPro = async () => {
        try {
            const data = await SpecialApi.getSpecialOffers();
            if (Array.isArray(data) && data.length > 0) {
                const offerId = data[0].id;
                const response = await SpecialApi.applySpecialOffer(offerId);
                const paymentUrl = response.payment_url;
                if (paymentUrl) {
                    window.location.href = paymentUrl;
                } else {
                    console.error("Ссылка для оплаты не найдена.");
                }
            } else {
                console.error("Нет доступных специальных предложений.");
            }
        } catch (error) {
            console.error("Ошибка при получении специальных предложений:", error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className={s.modalOverlay}>
            <div className={s.modalContent}>
                <div className={s.timer}>Предложение действует только {formatTime(timeLeft)} минут</div>
                <button onClick={onClose} className={s.closeIcon}>×</button>
                <div className={s.header}>
                    <div className={s.titleWrapper}>
                        <h4>Тариф «Профи»</h4>
                        <span className={s.discount}>-81%</span>
                    </div>
                    <h2>Успей получить больше возможностей за меньшую цену</h2>
                </div>
                
                <div className={s.benefits}>
                    <div className={s.mainBenefit}>
                        <span>Начислим</span>
                        <div className={s.benefitValues}>
                            <div className={s.tokens}>
                                <IcToken />
                                <span>350 000</span>
                            </div>
                            <div className={s.actions}>
                                <IcPremiumToken />
                                <span className={s.blueText}>100</span>
                            </div>
                        </div>
                    </div>
                    <ul className={s.benefitsList}>
  <li>
    <div className={s.row}>
      <span className={s.left}>5 документов</span>
      <span className={s.arrow}><RArrow /></span>
      <span className={s.right}><strong>Безлимит</strong> в редакторе текcт</span>
    </div>
  </li>
  <li>
    <div className={s.row}>
      <span className={s.left}>5 000 символов</span>
      <span className={s.arrow}><RArrow /></span>
      <span className={s.right}><strong>64 000 символов</strong> в окне контекста</span>
    </div>
  </li>
  <li >
    <div className={s.row}>
      <span className={s.left}>5 Мб</span>
      <span className={s.arrow}><RArrow /></span>
      <span className={s.right}><strong>40 Мб</strong> для загрузки файлом</span>
    </div>
  </li>
  <li>
    <div className={s.row}>
      <span className={s.left}>1 копия</span>
      <span className={s.arrow}><RArrow /></span>
      <span className={s.right}><strong>5 копий</strong> голоса</span>
    </div>
  </li>
  <li className={s.aiModels}>
    OpenAI + YandexGPT, GigaChat, Gemini, Claude, Llama, Dalle-E, FLUX, Playground, Ideogram, Recraft
  </li>
</ul>
                </div>

                <div className={s.footer}>
                    <button 
                        className={s.actionButton} 
                        onClick={handleSelectPro} 
                        disabled={!hasLink}
                    >
                        Выбрать «Профи» за <span style={{ textDecoration: 'line-through', color: 'rgba(211, 211, 211, 0.5)' }}>1050</span> 199 ₽/мес
                    </button>
                    <a
                        href="#"
                        className={s.link}
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = '/dashboard/user/tariffs';
                        }}
                    >
                        Подробнее о тарифах
                    </a>
                </div>
            </div>
        </div>
    );
};

export default WelcomeModal; 
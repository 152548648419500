import { Tooltip } from "@mui/material";
import React from "react";
const TOOLTIP_COLORS = {
  black: {
    bg: "var(--bg-inverted-primary-color)",
    color: "var(--text-inverted-primary-color)",
  },
  white: {
    bg: "white",
    color: "var(--text-primary-color)",
  },
  semiblack: {
    bg: "#000000B2",
    color: "white",
  },
};
const MuiCustomTooltip = ({
  title,
  children,
  placement = "bottom-start",
  zIndex = "1000",
  isBorder = true,
  isArrow = true,
  color = "black",
  padding = "12px",
}: {
  title: string | JSX.Element;
  children: JSX.Element;
  placement?:
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "top"
    | "top-start"
    | "top-end"
    | "right"
    | "right-start"
    | "right-end"
    | "left"
    | "left-start"
    | "left-end";
  zIndex?: string;
  isBorder?: boolean;
  isArrow?: boolean;
  color?: "black" | "white" | "semiblack";
  padding?: string;
}) => {
  const tooltipProps = {
    PopperProps: {
      sx: {
        zIndex,
      },
    },
    componentsProps: {
      tooltip: {
        sx: {
          padding,
          bgcolor: TOOLTIP_COLORS[color].bg,
          color: TOOLTIP_COLORS[color].color,
          fontSize: "14px",
          lineHeight: "21px",
          "& .MuiTooltip-arrow": {
            color: "var(--bg-inverted-primary-color)",
          },
        },
      },
    },

    color: "black",
    placement,
    arrow: isArrow,
  } as const;
  return (
    <Tooltip {...tooltipProps} title={title}>
      <div
        style={
          isBorder
            ? {
                borderBottom: "2px dotted var(--border-secondary-color)",
                width: "fit-content",
              }
            : {}
        }
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default MuiCustomTooltip;

const Picture = (props) => (
  <svg
    width={props.width || "20"}
    height={props.height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5004 6.66667H12.5087"
      stroke="#98A2B3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="2.5"
      y="4.16699"
      width="15"
      height="11.6667"
      rx="3"
      stroke="#98A2B3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 12.5L5.83333 9.16663C6.60684 8.42232 7.55983 8.42232 8.33333 9.16663L11.6667 12.5"
      stroke="#98A2B3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.833 11.6663L12.4997 9.99963C13.2732 9.25533 14.2262 9.25533 14.9997 9.99963L17.4997 12.4996"
      stroke="#98A2B3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Picture;

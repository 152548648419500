import React from "react";
import s from "./Skeleton.module.css";
import { classNames } from "shared/lib/classNames/classNames";
const Skeleton = ({
  height,
  width,
  color = "var(--bg-secondary-color)",
  inclined = true,
  cardParams = false,
}: {
  height?: string;
  width?: string;
  color?: string;
  inclined?: boolean;
  cardParams?: boolean;
}) => {
  return (
    <div className={s.card} style={cardParams ? { height } : {}}>
      <div
        className={classNames(`${s.cardImage} ${s.loading}`, {
          [s.inclined]: inclined,
        })}
        style={{ height, width, backgroundColor: color }}
      ></div>
    </div>
  );
};

export default Skeleton;

import React from "react";
import { ModalOverlay } from "shared/ui/ModalOverlay/ModalOverlay";
import s from "./LimitsModal.module.css";
import Button from "shared/ui/Button/Button";
import { BtnColor } from "shared/ui/Button/types";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import XClose from "shared/assets/icons/x-close";
import { useSelector } from "react-redux";
import { subscriptionsSelector } from "entities/UserSubPlan";
import { PLANS } from "pages/content/Tariffs";
import MuiCustomTooltip from "shared/ui/MuiCustomTooltip/ui/MuiCustomTooltip";

const LimitsModal = ({
  open,
  onClose,
  children,
  title,
  description,
  contentText,
  isTariffInfo,
  tariffInfoText,
}: {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  contentText?: string | JSX.Element;
  isTariffInfo?: boolean;
  tariffInfoText?: string | JSX.Element;
}) => {
  const userPlan = useSelector(subscriptionsSelector.userSubPlan);
  const profiPlan = PLANS["Профи"];
  const userPlanDescription =
    PLANS[userPlan?.maxUserPlan.title as keyof typeof PLANS];

  const POINTS = [
    {
      prevText: userPlanDescription?.symbols_count + " символов",
      currentText: profiPlan?.symbols_count + " символов",
      extraText: " окно контекста",
      tooltipTitle: "Допустимое количество символов на ввод",
    },
    {
      prevText: userPlanDescription?.files_size + " Мб",
      currentText: profiPlan?.files_size + " Мб",
      extraText: " размер загружаемого файла",
      tooltipTitle: "Максимальный объём одного зайгружаемого файла",
    },
    {
      prevText: userPlanDescription?.voice_cloning_count + " клонирование",
      currentText: profiPlan?.voice_cloning_count + " клонирований",
      extraText: " голоса",
      tooltipTitle: "Возможное количество загруженных Вами голосов",
    },
  ];
  return (
    <ModalOverlay open={open} onClose={onClose} className={"!z-[3001]"}>
      <div className={s.container}>
        <div className={s.info}>
          <div className="flex justify-between gap-3 items-start">
            <h2>{title}</h2>
            <button onClick={onClose}>
              <XClose />
            </button>
          </div>
          {description ? (
            <div className={s.description}>{description}</div>
          ) : (
            <p>
              <span className="text-gray-400">Текущий тариф</span>
              <br />
              <span className="text-gray-950">
                {userPlan?.maxUserPlan.title}
              </span>
            </p>
          )}
        </div>
        <div className={s.content}>
          {contentText ? (
            <div className="flex flex-col gap-2">
              <p className="text-[18px] font-medium">{contentText}</p>
              <p>
                <span className="text-gray-400">Текущий тариф</span>
                <br />
                {userPlan?.maxUserPlan.title}
              </p>
            </div>
          ) : isTariffInfo ? (
            <div className="flex flex-col gap-6">
              {tariffInfoText && (
                <p className="font-medium text-[18px] p-0">{tariffInfoText}</p>
              )}
              <div className="text-[14px]">
                {POINTS.map(
                  ({ prevText, currentText, extraText, tooltipTitle }) => (
                    <div key={prevText} className="flex gap-2 items-center">
                      <span className={s.prevDescription}>{prevText}</span>{" "}
                      <MuiCustomTooltip title={tooltipTitle} zIndex="3003">
                        <span className={s.descriptionPreferred}>
                          {currentText}
                        </span>
                      </MuiCustomTooltip>
                      <span> — {extraText}</span>
                    </div>
                  )
                )}
                {profiPlan?.gptModels.map((model, idx) => (
                  <span
                    key={model}
                    className={
                      !userPlanDescription?.gptModels?.includes(model)
                        ? s.descriptionPreferred
                        : ""
                    }
                  >
                    {model}
                    {idx !== profiPlan?.gptModels.length - 1 && ", "}
                  </span>
                ))}
                <p className={s.descriptionPreferred}>Замена фона</p>
              </div>
              <div className={s.pricesContainer}>
                <div className={s.price}>
                  <p className="text-[18px]">
                    <span className="text-[24px]">699 ₽</span> /мес
                  </p>
                  <span className="text-gray-400">При оплате за месяц</span>
                </div>
                <div className={s.pricePreferred}>
                  <p className="text-[18px]">
                    <span className="text-[24px]">525 ₽</span> /мес
                  </p>
                  <span className="text-gray-400">При оплате за год</span>
                </div>
              </div>
            </div>
          ) : (
            children
          )}
          <div className={s.btnsContainer}>
            <Button color={BtnColor.WHITE} onClick={onClose} isFull>
              Позже
            </Button>
            <Button
              color={BtnColor.GRADIENT}
              to={RoutePath.TARIFFS}
              isFull
              onClick={onClose}
            >
              Обновить тариф
            </Button>
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

export default LimitsModal;

import React, { useEffect, useState } from "react";
import styles from "./DragDropImg.module.css";
import { useDropzone } from "react-dropzone";
import ImageUp from "shared/assets/icons/image-up";
import Button from "shared/ui/Button/Button";
import { BtnColor, BtnSize } from "shared/ui/Button/types";
import Trash03 from "shared/assets/icons/trash-03";
import Retry from "shared/assets/icons/retry";
import MuiCustomTooltip from "shared/ui/MuiCustomTooltip/ui/MuiCustomTooltip";

type TUploadFileDrop = {
  onFilesLoad: any;
  imageFile: any;
};
const DRAG_ZONE_ID = "drag-zone";
const DragDropImg = ({ onFilesLoad, imageFile }: TUploadFileDrop) => {
  const [active, setActive] = useState(false);
  const [errorImgType, setErrorImgType] = useState<boolean>(false);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setActive(false);
      if (
        acceptedFiles[0].type !== "image/png" &&
        acceptedFiles[0].type !== "image/jpeg" &&
        acceptedFiles[0].type !== "image/jpg"
      ) {
        setErrorImgType(true);
        return;
      } else {
        setErrorImgType(false);
      }
      onFilesLoad(acceptedFiles[0]);
    },
    onDragEnter: () => {
      setActive(true); // активируем стиль при наведении
    },
    onDragLeave: () => {
      setActive(false); // сброс активности при покидании
    },
    onDragOver: (event) => {
      event.preventDefault();
    },
  });

  const tooltipProps = {
    isBorder: false,
    placement: "top",
    zIndex: "1000",
    isArrow: false,
    color: "semiblack",
    padding: "4px",
  } as const;
  return (
    <>
      <input
        {...getInputProps()}
        onChange={(e: any) => {
          if (e.target.files.length) {
            onFilesLoad(e.target.files[0]);
          }
        }}
        value={""}
        className="hidden"
        id="uploadForm_file"
        accept={"image/png, image/jpeg, image/jpg"}
      />
      {imageFile ? (
        <div className={styles.dragDropContainer}>
          <img
            src={imageFile ? URL.createObjectURL(imageFile) : ""}
            alt="image"
          />
          <div className={styles.dragDropBtnsContainer}>
            <MuiCustomTooltip title={"Повторить"} {...tooltipProps}>
              <label htmlFor="uploadForm_file" className={styles.retry}>
                <Retry />
              </label>
            </MuiCustomTooltip>
            <MuiCustomTooltip title={"Удалить"} {...tooltipProps}>
              <Button
                color={BtnColor.WHITE}
                size={BtnSize.XXSMALL}
                onClick={() => onFilesLoad(null)}
                animated={false}
              >
                <Trash03 />
              </Button>
            </MuiCustomTooltip>
          </div>
        </div>
      ) : (
        <label htmlFor="uploadForm_file" className="cursor-pointer">
          <div
            id={DRAG_ZONE_ID}
            className={`${styles.uploadZoneDragover} ${active ? styles.active : ""}`}
            {...getRootProps()} // применяем props от useDropzone
            onClick={() => {}}
          >
            <div className={styles.text}>
              <h2>Перетащите сюда для загрузки</h2>
              <span>JPG, PNG</span>
            </div>
            <div className={styles.mainText}>
              <div className="text-center">
                {errorImgType ? (
                  <p className="text-red-500">
                    К сожалению,
                    <br />
                    этот формат файла не поддерживается
                  </p>
                ) : (
                  <div>
                    <ImageUp />
                    <p className="pt-2 text-xs">
                      Загрузить <br />
                      изображение
                    </p>
                  </div>
                )}
              </div>
              <div className="text-center">
                <span className="text-gray-500 text-xs">
                  {errorImgType ? "или перетащите его сюда" : ""}
                </span>
              </div>
            </div>
          </div>
        </label>
      )}
    </>
  );
};
export default DragDropImg;

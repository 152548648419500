import { useAppDispatch } from "app/providers/StoreProvider/config/store";
import { VideoSidebar } from "pages/content/GeneratingVideo";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GeneratingContent from "widgets/GeneratingContent/GeneratingContent";
import { aiVideosThunks } from "../../model/thunks";
import { videoSelectors } from "../../model/selectors";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import Preloader from "shared/ui/Preloader/Preloader";

const VideoGeneratingPage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [promptText, setPromptText] = useState("");
  const [generatingLoadersCount, setGeneratingLoadersCount] = useState(0);
  const dispatch = useAppDispatch();
  const isLoading = useSelector(videoSelectors.isLoading);

  const isMoreVideos = useSelector(videoSelectors.isMoreVideos);
  const isGettingMoreVideos = useSelector(videoSelectors.isGettingMore);
  const generatedVideoList = useSelector(videoSelectors.generatedVideoList);
  const getGeneratedVideoList = () =>
    dispatch(aiVideosThunks.getGeneratedVideosList());

  const isError = useSelector(videoSelectors.isError);
  // TODO delete!!
  const navigate = useNavigate();
  useEffect(() => {
    navigate(RoutePath.MAIN);
  }, []);

  return <Preloader />;
  // TODO end
  return (
    <div className="flex w-full">
      <VideoSidebar
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
        isGenerating={generatingLoadersCount > 0}
        // @ts-ignore
        setGeneratingLoadersCount={setGeneratingLoadersCount}
        generatingLoadersCount={generatingLoadersCount}
        isLoading={isLoading}
        setPromptText={setPromptText}
        promptText={promptText}
        generatedItemsListLength={[].length}
      />
      <GeneratingContent
        generatingLoadersCount={generatingLoadersCount}
        isLoading={isLoading}
        generatedItemsList={generatedVideoList}
        setPromptText={setPromptText}
        setSidebarOpen={setSidebarOpen}
        type={"video"}
        getGeneratedItemsList={getGeneratedVideoList}
        isMoreItems={isMoreVideos}
        isGettingMoreItems={isGettingMoreVideos}
        isError={isError}
        handleDelete={aiVideosThunks.deleteVideo}
      />
    </div>
  );
};

export default VideoGeneratingPage;

import { createSlice } from "@reduxjs/toolkit";
import { TVideosSchema } from "./types";
import { aiVideosThunks } from "../thunks";

const initialState: TVideosSchema = {
  generatedVideosList: [],
  offset: 0,
  isLoading: false,
  isError: null,
  isMoreVideos: true,
  isGettingMore: false,
};

const videosSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsError(state, action) {
      state.isError = action.payload;
    },
    setIsGettingMore(state, action) {
      state.isGettingMore = action.payload;
    },
    setIsMoreVideos(state, action) {
      state.isMoreVideos = action.payload;
    },
    setAllVideos(state, action) {
      state.generatedVideosList = action.payload;
    },
    setOffset(state, { payload }) {
      state.offset = payload;
    },
    // todo create function because in image the same things
    updateVideos(state, { payload }) {
      if (payload.error) {
        state.generatedVideosList.shift();
      } else if (payload.loader) {
        state.generatedVideosList = [
          payload.loader,
          ...state.generatedVideosList,
        ];
      } else {
        //   // Находим индекс первого элемента, который не является загрузчиком
        //   const firstNonLoaderIndex = state.generatedVideosList.findIndex(
        //     // @ts-ignore
        //     (image) => !image.isLoader
        //   );

        //   // Если не нашли, значит все элементы - загрузчики и добавляем изображения в самый конец
        //   const startIndex =
        //     firstNonLoaderIndex === -1
        //       ? state.generatedVideosList.length - 1
        //       : firstNonLoaderIndex - 1;

        //   payload.images.reverse().forEach((image: any, index: number) => {
        //     // Обновляем только пока не выйдем за пределы массива
        //     if (startIndex - index >= 0) {
        //       state.generatedVideosList[startIndex - index] = image;
        //     }
        //   });
        state.generatedVideosList[0] = payload.video;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        aiVideosThunks.getGeneratedVideosList.fulfilled,
        (state, { payload }) => {
          if (payload)
            state.generatedVideosList = [
              ...state.generatedVideosList,
              ...payload.videosPortion,
            ];
        }
      )
      .addCase(aiVideosThunks.deleteVideo.fulfilled, (state, { payload }) => {
        if (payload) {
          state.generatedVideosList = state.generatedVideosList.filter(
            (video) => video.id !== payload.id
          );
        }
      });
  },
});

export const videosActions = videosSlice.actions;
export const videosReducer = videosSlice.reducer;

import React from "react";

export default function AiTemplatesIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6666 2.5V5.83333C11.6666 6.29357 12.0397 6.66667 12.5 6.66667H15.8333"
        stroke="#D0D5DD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1666 17.5H5.83329C4.91282 17.5 4.16663 16.7538 4.16663 15.8333V4.16667C4.16663 3.24619 4.91282 2.5 5.83329 2.5H11.6666L15.8333 6.66667V15.8333C15.8333 16.7538 15.0871 17.5 14.1666 17.5Z"
        stroke="#D0D5DD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { createPortal } from "react-dom";
import s from "./ModalOverlay.module.css";
import { memo, useEffect, useRef, useState } from "react";
import { classNames } from "shared/lib/classNames/classNames";
export interface TModalOverlay {
  onClose: () => void;
  open: boolean;
  children: React.ReactNode;
  className?: string;
}

const ANIMATION_DELAY = 300;
export const ModalOverlay = memo(
  ({ onClose, open, children, className = "" }: TModalOverlay) => {
    const [isVisible, setIsVisible] = useState(false);

    const timerRef = useRef<ReturnType<typeof setTimeout>>();
    useEffect(() => {
      if (open) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, [open]);

    const handleClose = () => {
      setIsVisible(false);
      timerRef.current = setTimeout(() => {
        onClose();
      }, ANIMATION_DELAY); // Задержка совпадает с продолжительностью анимации
    };

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleClose();
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", onKeyDown);
      return () => {
        if (timerRef.current) clearTimeout(timerRef.current);
        document.removeEventListener("keydown", onKeyDown);
      };
    }, []);

    return createPortal(
      <>
        <div
          className={classNames(s.modalContainer, { [s.open]: isVisible }, [
            className,
          ])}
          onClick={handleClose}
        >
          <div
            className={classNames(s.animated, { [s.animatedOpen]: isVisible })}
          >
            <div onClick={(e) => e.stopPropagation()}>{children}</div>
          </div>
        </div>
      </>,
      document.querySelector("body")!
    );
  }
);

// const Micro = (props) => (
//   <svg
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <rect
//       x="9"
//       y="2"
//       width="6"
//       height="11"
//       rx="3"
//       stroke={props.color}
//       strokeWidth="1.5"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     />
//     <path
//       d="M5 10C5 13.866 8.13401 17 12 17C15.866 17 19 13.866 19 10"
//       stroke={props.color}
//       strokeWidth="1.5"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     />
//     <path
//       d="M8 21H16"
//       stroke={props.color}
//       strokeWidth="1.5"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     />
//     <path
//       d="M12 17V21"
//       stroke={props.color}
//       strokeWidth="1.5"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     />
//   </svg>
// );

// export default Micro;
const Micro = (props) => (
  <svg
    width={props.width || "20"}
    height={props.height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8337 8.33366V10.0003C15.8337 13.222 13.222 15.8337 10.0003 15.8337M4.16699 8.33366V10.0003C4.16699 13.222 6.77866 15.8337 10.0003 15.8337M10.0003 15.8337V18.3337M6.66699 18.3337H13.3337M10.0003 12.5003C8.61961 12.5003 7.50033 11.381 7.50033 10.0003V4.16699C7.50033 2.78628 8.61961 1.66699 10.0003 1.66699C11.381 1.66699 12.5003 2.78628 12.5003 4.16699V10.0003C12.5003 11.381 11.381 12.5003 10.0003 12.5003Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Micro;

import { createSlice } from "@reduxjs/toolkit";
import { defaultSettingsThunks } from "../services/thunks/defaultSettings";

export interface TDefaultSettingsSchema {
  settings: {
    generate_ai_chat_name: boolean | null;
  };
  isLoading: boolean;
}
const initialState: TDefaultSettingsSchema = {
  settings: {
    generate_ai_chat_name: null,
  },
  isLoading: false,
};

const defaultSettingsSlice = createSlice({
  name: "defaultSettings",
  initialState,
  reducers: {
    isLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        defaultSettingsThunks.getDefaultSettings.fulfilled,
        (state, { payload }) => {
          if (payload) {
            state.settings = payload;
          }
        }
      )
      .addCase(
        defaultSettingsThunks.updateDefaultUserSettings.fulfilled,
        (state, { payload }) => {
          console.log(payload);
          if (payload) {
            state.settings = payload;
          }
        }
      );
  },
});

export const defaultSettingsReducer = defaultSettingsSlice.reducer;
export const defaultSettingsActions = defaultSettingsSlice.actions;

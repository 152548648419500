const SERVICE_NAME = "Mono Ai";

export const PAGE_TITLES = {
  LOGIN: `${SERVICE_NAME} | Вход`,
  TGLINK: `${SERVICE_NAME} | Вход телеграм`,
  REGISTER: `${SERVICE_NAME} | Регистрация`,
  FORGOT_PASSWORD: `${SERVICE_NAME} | Восстановление пароля`,
  RESET_PASSWORD: `${SERVICE_NAME} | Новый пароль`,
  CHAT_BOT: `${SERVICE_NAME} | Чат-бот`,
  AI_TEMPLATES: `${SERVICE_NAME} | Шаблоны`,
  AI_IMAGES: `${SERVICE_NAME} | Изображения`,
  AI_EXPERTS: `${SERVICE_NAME} | Эксперты`,
  MAIN: `${SERVICE_NAME} | Главная`,
  NOT_FOUND: `${SERVICE_NAME} | Страница не найдена`,
  SETTINGS: `${SERVICE_NAME} | Настройки`,
  PAYMENT: `${SERVICE_NAME} | Тарифы`,
  AUDIO_TRANSCRIPT: `${SERVICE_NAME} | Расшифровка аудио`,
  DOCUMENTS: `${SERVICE_NAME} | Документы`,
  BACKGROUND_REPLACEMENT: `${SERVICE_NAME} | Замена фона`,
  SUPPORT: `${SERVICE_NAME} | Служба поддержки`,
  VOICE_TRANSCRIPT: `${SERVICE_NAME} | Озвучка текста`,
  YANDEX_AUTH_CALLBACK: `${SERVICE_NAME} | Aвторизация на Яндексе`,

  ADMIN_USERS: `${SERVICE_NAME} | Пользователи`,
  ADMIN_STATISTICS: `${SERVICE_NAME} | Статистика`,
};

export const TARIFFS_NAMES = {
  FREE: "Бесплатный",
  START: "Старт",
  PROFI: "Профи",
  MASTER: "Мастер",
  PREMIUM: "Премиум",
};

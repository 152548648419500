import { createAsyncThunk } from "@reduxjs/toolkit";
import { imagesActions } from "../../slice";
import ImageApi from "../api/imagesAPI";

const IMAGES_PORTION = 24;

export const getGeneratedImagesList = createAsyncThunk(
  "aiImages/getGeneratedImagesList",
  async (_, thunkAPI) => {
    const { rejectWithValue, dispatch, getState } = thunkAPI;

    // @ts-ignore
    const { generatedImagesList, offset } = getState().aiImages;
    try {
      if (!generatedImagesList.length) {
        dispatch(imagesActions.setIsLoading(true));
      } else {
        dispatch(imagesActions.setIsGettingMore(true));
      }

      const res = await ImageApi.getAllGeneratedImages({ offset });

      dispatch(imagesActions.setOffset(offset + IMAGES_PORTION));

      if (IMAGES_PORTION > res.thumbnails.length) {
        dispatch(imagesActions.setIsMoreImages(false));
      }
      return { imagesPortion: res.thumbnails };
    } catch (e) {
      // dispatch(imagesActions.isError(e));
      rejectWithValue(e);
    } finally {
      dispatch(imagesActions.setIsLoading(false));
      dispatch(imagesActions.setIsGettingMore(false));
    }
  }
);

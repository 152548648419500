export const Dice = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8333 5.16667H10.8367M8 8H8.00333M5.16667 10.8333H5.17M5.2 14H10.8C11.9201 14 12.4802 14 12.908 13.782C13.2843 13.5903 13.5903 13.2843 13.782 12.908C14 12.4802 14 11.9201 14 10.8V5.2C14 4.0799 14 3.51984 13.782 3.09202C13.5903 2.71569 13.2843 2.40973 12.908 2.21799C12.4802 2 11.9201 2 10.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14ZM11 5.16667C11 5.25871 10.9254 5.33333 10.8333 5.33333C10.7413 5.33333 10.6667 5.25871 10.6667 5.16667C10.6667 5.07462 10.7413 5 10.8333 5C10.9254 5 11 5.07462 11 5.16667ZM8.16667 8C8.16667 8.09205 8.09205 8.16667 8 8.16667C7.90795 8.16667 7.83333 8.09205 7.83333 8C7.83333 7.90795 7.90795 7.83333 8 7.83333C8.09205 7.83333 8.16667 7.90795 8.16667 8ZM5.33333 10.8333C5.33333 10.9254 5.25871 11 5.16667 11C5.07462 11 5 10.9254 5 10.8333C5 10.7413 5.07462 10.6667 5.16667 10.6667C5.25871 10.6667 5.33333 10.7413 5.33333 10.8333Z"
      stroke="#98A2B3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { imagesSelectors } from "../../model/selectors";
import { useAppDispatch } from "app/providers/StoreProvider/config/store";
import { aiImagesThunks } from "../../model/services/thunks";
import GeneratingContent from "widgets/GeneratingContent/GeneratingContent";

const GeneratingImagesPage = () => {
  const dispatch = useAppDispatch();

  const isMoreImages = useSelector(imagesSelectors.isMoreImages);
  const isGettingMoreImages = useSelector(imagesSelectors.isGettingMore);
  const generatedImagesList = useSelector(imagesSelectors.generatedImagesList);
  const isError = useSelector(imagesSelectors.isError);
  // @ts-ignore
  const [generatingLoadersCount, isLoading, setPromptText, setSidebarOpen] =
    useOutletContext();

  const getGeneratedImagesList = () =>
    dispatch(aiImagesThunks.getGeneratedImagesList());

  return (
    <GeneratingContent
      generatedItemsList={generatedImagesList}
      isLoading={isLoading}
      generatingLoadersCount={generatingLoadersCount}
      setPromptText={setPromptText}
      setSidebarOpen={setSidebarOpen}
      type="image"
      getGeneratedItemsList={getGeneratedImagesList}
      isMoreItems={isMoreImages}
      isGettingMoreItems={isGettingMoreImages}
      isError={isError}
      getFullItem={aiImagesThunks.getImageFullUrl}
      handleDelete={aiImagesThunks.deleteImage}
    />
  );
};

export default GeneratingImagesPage;

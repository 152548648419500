const Retry = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 15.3742C12.5208 15.3742 15.375 12.52 15.375 8.99922C15.375 6.88007 14.341 5.00242 12.75 3.84327M9.75 16.7992L8.25 15.2992L9.75 13.7992M9 2.62422C5.47918 2.62422 2.625 5.4784 2.625 8.99922C2.625 11.1184 3.659 12.996 5.25 14.1552M8.25 4.19922L9.75 2.69922L8.25 1.19922"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Retry;

import Stable from "shared/assets/images/Stable.png";
import Auto from "shared/assets/images/Auto.svg";
import Model3D from "shared/assets/images/3DModel.png";
import Comic from "shared/assets/images/Comic.png";
import Fantasy from "shared/assets/images/Fantasy.png";
import anime from "shared/assets/images/JAnime.png";
import Kiber from "shared/assets/images/Kiber.png";
import Movie from "shared/assets/images/Movie.png";
import analogFilm from "shared/assets/images/AnalogFilm.png";
import digitalArt from "shared/assets/images/digital-art.png";
import enhance from "shared/assets/images/enhance.png";
import isometric from "shared/assets/images/isometric.png";
import lineArt from "shared/assets/images/line-art.png";
import lowPoly from "shared/assets/images/low-poly.png";
import modelingCompound from "shared/assets/images/modeling-compound.png";
import origami from "shared/assets/images/origami.png";
import pixelArt from "shared/assets/images/pixel-art.png";
import photographic from "shared/assets/images/photographic.png";
import tileTexture from "shared/assets/images/tile-texture.png";

import { RoutePath } from "shared/config/routeConfig/routeConfig";

import flux from "shared/assets/icons/Flux.svg";
import playground from "shared/assets/icons/Playground_logo.svg";
import stableDef from "shared/assets/icons/Stable_Diffusion_logo.svg";
import dalle from "shared/assets/icons/OpenAI_Logo.svg";
import ideogram from "shared/assets/icons/Ideogram_logo.svg";
import recraft from "shared/assets/icons/Recraft_logo.svg";

import img_generation from "shared/assets/images/image_generation.webp";
import img_background from "shared/assets/images/bg_replacement.webp";
import img_remove_background from "shared/assets/images/delete_bg.webp";
import img_face_replacement from "shared/assets/images/face_change.webp";
import img_enhance from "shared/assets/images/enhance_img.webp";
import img_remove_watermark from "shared/assets/images/delete_watermark.webp";
import img_style_transfer from "shared/assets/images/changing_image_style.webp";
import img_logo_generation from "shared/assets/images/intelligent-logo-simple.webp";
export interface IModel {
  id: number;
  title: string;
  description: string;
  count: number;
  icon: string;
}

export const FAL_ENDPOINTS = {
  "FLUX1.1 [pro] ultra": "image-flux-ultra",
  "FLUX1.1 [pro]": "image-flux-pro",
  "FLUX.1 LoRA": "image-flux-lora",
  "FLUX.1 dev": "image-flux-dev",
  "FLUX.1 schnell": "image-schnell",
  "PLAYGROUND-v25": "image-playground",
  "DALL·E": "bulk/image",
  "Stable Image Ultra": "bulk/image_stable_diffusion_ultra",
  "Stable Image Core": "bulk/image_stable_diffusion_core",
  "Stable Diffusion 3.5": "bulk/image_stable_diffusion_3_5",
  "IDEOGRAM-v2": "bulk/image-ideogram",
  "RECRAFT-v3": "bulk/image-recraft",
};

export const IMAGE_GPT_MODELS = {
  "Stable Diffusion": {
    "Stable Image Ultra": {
      tokens_usage: 7,
      description:
        "Флагманский сервис генерации изображений на основе Stable Diffusion 3.5 Large, обеспечивающий наивысшее качество и детализацию",
      usage: ["special"],
    },
    "Stable Diffusion 3.5 Large": {
      tokens_usage: 5.5,
      description:
        "SD 3.5 - самая мощная базовая модель Stable Diffusion, обеспечивающая превосходное качество и точное следование запросу",
      usage: ["high-quality"],
    },
    "Stable Diffusion 3.5 Large Turbo": {
      tokens_usage: 4,
      description:
        "Турбо-версия Stable Diffusion 3/3.5 Large для быстрой генерации качественных изображений",
      usage: ["fast"],
    },
    "Stable Diffusion 3.5 Medium": {
      tokens_usage: 3.5,
      description:
        "Варианты Stable Diffusion 3 и 3.5 с 2 и 2.5 с пониманием множества параметров соответственно",
      usage: ["fast"],
    },
    "Stable Image Core": {
      tokens_usage: 3,
      description:
        "Оптимизированная для быстрой и экономичной генерации изображений",
      usage: ["fast"],
    },
    // "SDXL 1.0": {
    //   tokens_usage: 2,
    //   description: "Старая базовая модель для простой генерации изображений",
    //   usage: ["fast"],
    // },
    // "SD 1.6": {
    //   tokens_usage: 2,
    //   description:
    //     "Старая базовая модель с гибким разрешением для генерации изображений с нестандартным соотношением сторон",
    //   usage: ["fast"],
    // },
  },
  Flux: {
    "FLUX1.1 [pro] ultra": {
      tokens_usage: 9,
      description:
        "Обновлённая версия, генерирующая изображения профессионального качества с разрешением до 2K. Подходит для задач, где важна реалистичность",
      usage: ["high-quality"],
      isNew: true,
      from: true,
    },
    "FLUX1.1 [pro]": {
      tokens_usage: 8,
      description:
        "Улучшенная версия с высокой детализацией и художественной точностью. Идеальна для тех, кто стремится к качественной композиции и деталям.",
      usage: ["high-quality"],
      from: true,
    },
    "FLUX.1 LoRA": {
      tokens_usage: 7,
      description:
        "Быстрая генерация изображений с возможностью персонализации, добавления уникальных стилей и адаптации под бренды",
      usage: ["special"],
      isNew: true,
      from: true,
    },
    "FLUX.1 dev": {
      tokens_usage: 5,
      description:
        "Базовая модель для создания изображений на основе текста. Подходит как для личного использования, так и для коммерческих задач.",
      usage: ["fast"],
      isNew: true,
      from: true,
    },
    "FLUX.1 schnell": {
      tokens_usage: 2,
      description:
        "Упрощённая версия, создающая качественные простые изображения. Отличный выбор для тех, у кого легкие задачи, требующие быстрой генерации.",
      usage: ["fast"],
      isNew: true,
      from: true,
    },
  },
  "Dalle-E": {
    "DALL·E 3 (Standard)": {
      tokens_usage: 4,
      description:
        "Модель генерирует контент, отличающийся фотореализмом и художественностью",
      usage: ["fast"],
    },
    "DALL·E 3 (HD)": {
      tokens_usage: 10,
      description:
        "Высококачественная генерация изображений, подходящая для задач, где важна реалистичность",
      usage: ["high-quality"],
    },
  },
  Playground: {
    "PLAYGROUND-v25": {
      tokens_usage: 1,
      description: "Идеальная модель для генерации творческих изображений",
      usage: ["special"],
      isNew: true,
    },
  },
  Ideogram: {
    "IDEOGRAM-v2": {
      tokens_usage: 7,
      description:
        "Модель ориентирована на типографическое искусство и создание уникального дизайна бренда",
      usage: ["special"],
      isNew: true,
    },
  },
  Recraft: {
    "RECRAFT-v3": {
      tokens_usage: 4,
      description:
        "Модель для создания изображения по тексту с возможностью создавать векторную графику",
      usage: ["special"],
      isNew: true,
    },
  },
} as const;

export const MODELS_ICONS = {
  "Stable Diffusion": stableDef,
  Flux: flux,
  "Dalle-E": dalle,
  Playground: playground,
  Ideogram: ideogram,
  Recraft: recraft,
};

export interface IStyleOptions {
  name: string;
  icon: string;
  label: string;
}
export const STYLES_MAP = {
  "3d-model": "3D модель",
  "analog-film": "Аналоговая камера",
  anime: "Аниме",
  cinematic: "Кино",
  "comic-book": "Комикс",
  "digital-art": "Цифровое искусство",
  enhance: "Улучшение качества",
  "fantasy-art": "Фэнтези",
  isometric: "Изометрия",
  "low-poly": "Лоу-поли",
  "line-art": "Лайн арт",
  "modeling-compound": "Объемный",
  "neon-punk": "Киберпанк",
  origami: "Оригами",
  photographic: "Фотореалистичный",
  "pixel-art": "Пиксельный",
  "tile-texture": "Мозаичный",
};

export const styleOptions: IStyleOptions[] = [
  { name: "Авто", icon: Auto, label: "auto" },
  { name: "3D модель", label: "3d-model", icon: Model3D },
  { name: "Аналоговая камера", label: "analog-film", icon: analogFilm },
  { name: "Аниме", label: "anime", icon: anime },
  { name: "Кино", label: "cinematic", icon: Movie },
  { name: "Комикс", label: "comic-book", icon: Comic },
  { name: "Цифровое искусство", label: "digital-art", icon: digitalArt },
  { name: "Улучшение качества", label: "enhance", icon: enhance },
  { name: "Фэнтези", label: "fantasy-art", icon: Fantasy },
  { name: "Изометрия", label: "isometric", icon: isometric },
  { name: "Лайн арт", label: "line-art", icon: lineArt },
  { name: "Лоу-поли", label: "low-poly", icon: lowPoly },
  { name: "Объемный", label: "modeling-compound", icon: modelingCompound },
  { name: "Киберпанк", label: "neon-punk", icon: Kiber },
  { name: "Оригами", label: "origami", icon: origami },
  { name: "Фотореалистичный", label: "photographic", icon: photographic },
  { name: "Пиксельный", label: "pixel-art", icon: pixelArt },
  { name: "Мозаичный", label: "tile-texture", icon: tileTexture },
];

export const aspectRatios = [
  { label: "3:2", icon: "3:2" },
  { label: "2:3", icon: "2:3" },
  { label: "4:3", icon: "4:3" },
  { label: "3:4", icon: "3:4" },
  { label: "5:4", icon: "5:4" },
  { label: "4:5", icon: "4:5" },
  { label: "16:9", icon: "16:9" },
  { label: "9:16", icon: "9:16" },
  { label: "21:9", icon: "21:9" },
  { label: "9:21", icon: "9:21" },
  { label: "1:1", icon: "1:1" },
];

const SD_MAIN_RATIOS = {
  "21:9": ["1536x640"],
  "9:21": ["640x1536"],
  "16:9": ["1344x768"],
  "9:16": ["768x1344"],
  "5:4": ["1088x896"],
  "4:5": ["896x1088"],
  "3:2": ["1216x832"],
  "2:3": ["832x1216"],
  "1:1": ["1024x1024"],
};
const FLUX_MAIN_RATIONS = {
  "16:9": ["1024x576"],
  "9:16": ["576x1024"],
  "4:3": ["1024x768"],
  "3:4": ["768x1024"],
  "1:1": ["512x512"],
  "1:1 HD": ["1024x1024"],
};
export const RATIO = {
  "Stable Image Ultra": SD_MAIN_RATIOS,
  "Stable Diffusion 3.5 Large": SD_MAIN_RATIOS,
  "Stable Diffusion 3.5 Large Turbo": SD_MAIN_RATIOS,
  "Stable Diffusion 3.5 Medium": SD_MAIN_RATIOS,
  "Stable Image Core": {
    "21:9": ["2336x992"],
    "9:21": ["992x2336"],
    "16:9": ["2040x1152"],
    "9:16": ["1152x2040"],
    "5:4": ["1712x1368"],
    "4:5": ["1368x1712"],
    "3:2": ["1872x1248"],
    "2:3": ["1248x1872"],
    "1:1": ["1536x1536"],
  },
  "SDXL 1.0": {
    "4:3": ["1152x896", "896x1152"],
    "3:4": ["768x1344", "1344x768"],
    "3:2": ["1216x832"],
    "16:9": ["1536x640", "640x1536"],
  },
  // todo correct sizes
  "SD 1.6": {
    "16:9": ["1024x576"],
    "9:16": ["576x1024"],
    "4:3": ["1024x768"],
    "3:4": ["768x1024"],
    "1:1": ["512x512", "1536x1536", "1024x1024"],
  },

  "FLUX1.1 [pro] ultra": {
    "21:9": ["3136x1344"],
    "9:21": ["1344x3136"],
    "16:9": ["2752x1536"],
    "9:16": ["1536x2752"],
    "4:3": ["2368x1792"],
    "3:4": ["1792x2368"],
    "3:2": ["2496x1664"],
    "2:3": ["1664x2496"],
    "1:1": ["2048x2048"],
  },
  "FLUX1.1 [pro]": FLUX_MAIN_RATIONS,
  "FLUX.1 LoRA": FLUX_MAIN_RATIONS,
  "FLUX.1 dev": FLUX_MAIN_RATIONS,
  "FLUX.1 schnell": FLUX_MAIN_RATIONS,

  "DALL·E 3 (Standard)": {
    "1:1": ["1024x1024"],
  },
  "DALL·E 3 (HD)": {
    "16:9": ["1792x1024"],
    "9:16": ["1024x1792"],
  },
  "PLAYGROUND-v25": FLUX_MAIN_RATIONS,
  // todo correct sizes
  "IDEOGRAM-v2": {
    "16:10": ["1280x800"],
    "16:9": ["1312x736"],
    "4:3": ["1152x864"],
    "3:1": ["1536x512"],
    "3:2": ["1248x832"],
    "1:1": ["1024x1024"],
  },
  "RECRAFT-v3": FLUX_MAIN_RATIONS,
};
export const BtnImagesLink = [
  {
    id: 1,
    title: "Генерация изображения",
    description: "Сгенерируйте любую свою фантазию в формате JPG или PNG",
    img: img_generation,
    link: RoutePath.GENERATION_IMAGES,
  },
  {
    id: 2,
    title: "Замена фона",
    description: "Легко замените фон ваших изображений",
    img: img_background,
    link: RoutePath.BACKGROUND_REPLACEMENT,
    notFree: true,
  },
  {
    id: 3,
    title: "Удаление фона",
    description: "Удалите фон из изображения с помощью специальных алгоритмов",
    img: img_remove_background,
    link: "",
    disabled: true,
  },
  {
    id: 4,
    title: "Замена лица",
    description:
      "Замените лицо на фотографии, сохранив остальное без изменений",
    img: img_face_replacement,
    link: "",
    disabled: true,
  },
  {
    id: 5,
    title: "Улучшение качества",
    description: "Увеличьте разрешение изображения без потери качества",
    img: img_enhance,
    link: "",
    disabled: true,
  },
  {
    id: 6,
    title: "Удаление водяных знаков",
    description: "Удалите метки с изображений для восстановления оригиналов",
    img: img_remove_watermark,
    link: "",
    disabled: true,
  },
  {
    id: 7,
    title: "Изменение стиля изображения",
    description: "Примените художественные стили к вашим изображениям",
    img: img_style_transfer,
    link: "",
    disabled: true,
  },
  {
    id: 8,
    title: "Генерация логотипов",
    description: "Создайте уникальный логотип для вашего дела",
    img: img_logo_generation,
    link: "",
    disabled: true,
  },
];

import PageTitle from "shared/ui/PageTitle/PageTitle";
import s from "./GeneratingImagesNew.module.css";
import { BtnImagesLink } from "../../constants/settings";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PAGE_TITLES, TARIFFS_NAMES } from "shared/constants/common";
import { useSelector } from "react-redux";
import { subscriptionsSelector } from "entities/UserSubPlan";
import LimitsModal from "features/LimitsModal/LimitsModal";
import IcLock04 from "shared/assets/icons/IcLock-04";
import { BadgeColor } from "shared/ui/Badge/types";
import Badge from "shared/ui/Badge/Badge";
import { LS_NO_IMAGE_BANNER_ON_PAGE } from "shared/api/constants/localStorage";
import AccessToModelsBanner from "shared/ui/AccessToModelsBanner/AccessToModelsBanner";

const GeneratingImagesNew = () => {
  const [sectionLimits, setSectionLimits] = useState<string | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = PAGE_TITLES.AI_IMAGES;
  }, []);

  const userPlan = useSelector(subscriptionsSelector.userSubPlan);

  const freeTariffLimits =
    userPlan?.maxUserPlan.title === TARIFFS_NAMES.FREE ||
    userPlan?.maxUserPlan.title === TARIFFS_NAMES.START;
  return (
    <div className={s.imagesContainer}>
      <PageTitle title="Изображения" />
      <AccessToModelsBanner title="фото" lsKey={LS_NO_IMAGE_BANNER_ON_PAGE} />

      <div className={s.imagesContainerBox}>
        {BtnImagesLink.map((images) => (
          <div
            key={images.id}
            className={
              s.pageImagesGridBox + " " + (images.disabled && s.disabled)
            }
            onClick={() => {
              if (images.notFree && freeTariffLimits) {
                setSectionLimits(images.title);
                return;
              }
              !images.disabled && navigate(images.link);
            }}
            style={{
              backgroundImage: `url(${images.img})`,
              opacity: `${images.disabled ? "80%" : "1"}`,
            }}
          >
            <div className={s.pageImagesGridDesc}>
              {images.notFree && freeTariffLimits && (
                <div className=" absolute top-2 right-2">
                  <IcLock04 />
                </div>
              )}
              {images.disabled && (
                <div className="absolute bottom-6 right-6">
                  <Badge color={BadgeColor.BLUE}>СКОРО</Badge>
                </div>
              )}
              <p className={s.pageImagesTitle}>{images.title}</p>
              <p className={s.pageImagesDesc}>{images.description}</p>
            </div>
          </div>
        ))}
        {/* <button className={s.pageImagesShow}>Показать еще</button> */}
      </div>
      {/* <div className={s.pageImagesExampleBox}>
        <p className={s.pageImagesExampleBoxTitle}>Вдохновляйтесь работами</p>
        <div className={s.pageImagesExampleContent}>
          <div className={s.pageImagesExampleBoxFilter}>
            <button className={`${s.boxFilterBtn} ${s.activeBtn}`}>
              Витрина
            </button>
            <button className={s.boxFilterBtn}>Моя коллекция</button>
          </div>
        </div>
      </div> */}
      <LimitsModal
        open={Boolean(sectionLimits)}
        onClose={() => setSectionLimits(null)}
        title={
          <>Перейди на следующий уровень и открой раздел "{sectionLimits}"!</>
        }
        isTariffInfo
        tariffInfoText={
          <>
            Поменяй тариф, сохрани текущие токены
            <br />и получи новые возможности. Замена фона доступна от тарифа
            «Профи»
          </>
        }
      />
    </div>
  );
};

export default GeneratingImagesNew;

import useHttp from "shared/api/services/api"; // Импортируем useHttp
import { API_PREFIX } from "shared/api/constants/api"; // Импортируем API_PREFIX

export default class VideoApi {
  static http = useHttp(API_PREFIX.AI_VIDEO);
  // Метод для получения всех сгенерированных изображений
  static async getAllGeneratedVideos({
    offset,
    limit = 24,
  }: {
    offset: number;
    limit?: number;
  }) {
    const { data } = await VideoApi.http.get(
      `/list?offset=${offset}&limit=${limit}`
    );
    return data;
  }
  static async getVideoById(id: string) {
    const { data } = await VideoApi.http.get(`/${id}`);
    return data;
  }
  static async deleteVideo(id: string) {
    const { data } = await VideoApi.http.delete(`/${id}`);
    return data;
  }
}

export const PLANS_DISCOUNTS = {
  monthly: {
    Профи: 33,
    Мастер: 50,
    Премиум: 70,
  },
  annual: {
    Старт: 20,
    Профи: 50,
    Мастер: 61.9,
    Премиум: 75.38,
  },
};
// export const PREVIOUS_PRICES = {
//   monthly: {},
//   annual: {
//     // Старт: 299,
//   },
// };

export const PLANS = {
  Бесплатный: {
    description:
      "Чтобы увидеть эффективность сервиса: базовый доступ к функциям для знакомства.",
    documents_count: 5,
    symbols_count: 5000,
    files_size: 5,
    voice_cloning_count: 1,
    voice_duration: 1,
    gptModels: ["OpenAI"],
    ai_templates: true,
    ai_images_models: ["Stabble Diffusion"],
    bg_replacement: false,
    support: false,
    words_per_month: 2000,
    images_per_month: 2,
  },
  Старт: {
    description:
      "Полный доступ к функциям, достаточный объём токенов для регулярного использования.",
    previous_price: 299,
    documents_count: 100,
    symbols_count: 16000,
    files_size: 25,
    voice_cloning_count: 1,
    voice_duration: 5,
    gptModels: ["OpenAI", "YandexGPT", "GigaChat"],
    ai_images_models: ["Stabble Diffusion", "Dalle", "Flux"],
    bg_replacement: false,
    ai_templates: true,
    support: false,
    words_per_month: 20000,
    images_per_month: 12,
    images_per_year: 150,
  },
  Профи: {
    description:
      "Увеличенный объём всех токенов, доступ к профессиональным функциям.",
    previous_price: 1050,
    documents_count: "Безлимит",
    symbols_count: 64000,
    files_size: 40,
    voice_cloning_count: 5,
    voice_duration: 15,
    gptModels: ["OpenAI", "YandexGPT", "GigaChat", "Gemini", "Claude", "Llama"],
    bg_replacement: true,
    ai_templates: true,
    ai_images_models: ["Stabble Diffusion", "Dalle", "Flux"],
    support: false,
    words_per_month: 70000,
    images_per_month: 25,
  },
  Мастер: {
    description: "Больше возможностей для творческих и рабочих проектов.",
    previous_price: 2600,
    documents_count: "Безлимит",
    symbols_count: 200000,
    files_size: 50,
    voice_cloning_count: 20,
    voice_duration: 40,
    ai_templates: true,

    gptModels: ["OpenAI", "YandexGPT", "GigaChat", "Gemini", "Claude", "Llama"],
    bg_replacement: true,

    ai_images_models: ["Stabble Diffusion", "Dalle", "Flux"],
    video_generation: true,
    support: true,
    words_per_month: 200000,
    images_per_month: 62,
    images_per_year: 750,
  },
  Премиум: {
    description:
      "Максимальный объём токенов и полный доступ ко всем премиум функциям.",
    previous_price: 12960,
    documents_count: "Безлимит",
    symbols_count: 200000,
    files_size: 100,
    voice_cloning_count: 100,
    voice_duration: 60,
    gptModels: ["OpenAI", "YandexGPT", "GigaChat", "Gemini", "Claude", "Llama"],
    bg_replacement: true,
    ai_images_models: ["Stabble Diffusion", "Dalle", "Flux"],
    support: true,
    ai_templates: true,
    video_generation: true,
    access_new_models: true,
    personal_assistant: true,
    words_per_month: 1000000,
    images_per_month: 250,
  },
  // "Премиум Годовой": {
  //   description:
  //     "Максимальный объём токенов и полный доступ ко всем премиум функциям.",
  //   previous_price: 12960,
  //   documents_count: "Безлимит",
  //   symbols_count: 200000,
  //   files_size: 100,
  //   voice_cloning_count: 100,
  //   voice_duration: 60,
  //   gptModels: ["OpenAI", "YandexGPT", "GigaChat", "Gemini", "Claude", "Llama"],
  //   bg_replacement: true,
  //   ai_images_models: ["Stabble Diffusion", "Dalle", "Flux"],
  //   support: true,
  //   ai_templates: true,
  //   video_generation: true,
  //   access_new_models: true,
  //   personal_assistant: true,
  //   words_per_month: 1000000,
  //   images_per_month: 250,
  // },
};
export const VOICES = {
  1: "голос",
  5: "голосов",
  20: "голосов",
  100: "голосов",
} as const;

const Logo = (props) => (
  <svg
    width="152"
    height="24"
    viewBox="0 0 152 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0129 0L11.7439 10.838L5.47491 0H0.0503311H0V24H4.69757V8.03386L11.4151 19.6465H12.0728L18.7903 8.03386V24H23.4879V0H23.4375H18.0129Z"
      fill="var(--text-primary-color)"
    />
    <path
      d="M38.5314 0C31.891 0 26.5078 5.37265 26.5078 12C26.5078 18.6273 31.891 24 38.5314 24C45.1717 24 50.5549 18.6273 50.5549 12C50.5549 5.37265 45.1717 0 38.5314 0ZM38.5314 19.3116C34.4859 19.3116 31.2054 16.0376 31.2054 12C31.2054 7.96242 34.4859 4.68837 38.5314 4.68837C42.5769 4.68837 45.8573 7.96242 45.8573 12C45.8573 16.0376 42.5769 19.3116 38.5314 19.3116Z"
      fill="var(--text-primary-color)"
    />
    <path
      d="M72.1408 0H67.4433V14.294L57.415 0H53.5742V24H58.2718V9.3946L68.5181 24H72.1408V0Z"
      fill="var(--text-primary-color)"
    />
    <path
      d="M87.1847 0C80.5443 0 75.1611 5.37265 75.1611 12C75.1611 18.6273 80.5443 24 87.1847 24C93.825 24 99.2082 18.6273 99.2082 12C99.2082 5.37265 93.825 0 87.1847 0ZM87.1847 19.3116C83.1392 19.3116 79.8587 16.0376 79.8587 12C79.8587 7.96242 83.1392 4.68837 87.1847 4.68837C91.2302 4.68837 94.5107 7.96242 94.5107 12C94.5107 16.0376 91.2302 19.3116 87.1847 19.3116Z"
      fill="var(--text-primary-color)"
    />
    <path
      d="M136.156 8.93023C134.44 2.0093 131.197 0 125.605 0C120.012 0 116.804 2.0093 115.053 8.93023C113.301 15.8512 111.288 24 111.288 24H117.328C117.328 24 120.607 10.9395 121.093 8.93023C121.578 6.92093 122.808 2.0093 125.605 2.0093C128.401 2.0093 129.595 6.92093 130.116 8.93023C130.638 10.9395 133.881 24 133.881 24H139.921C139.921 24 137.872 15.8512 136.156 8.93023Z"
      fill="#2871FF"
    />
    <path d="M152 0H145.96V24H152V0Z" fill="#2871FF" />
  </svg>
);
export const LogoSmall = () =>(
  <svg width="128" height="20" viewBox="0 0 128 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_6630_194285)">
<path d="M15.6774 0L10.4532 9.03163L5.22905 0H0.708569H0.666626V20H4.58127V6.69488L10.1792 16.3721H10.7273L16.3252 6.69488V20H20.2398V0H20.1979H15.6774Z" fill="#101018"/>
<path d="M32.7761 0C27.2425 0 22.7565 4.47721 22.7565 10C22.7565 15.5228 27.2425 20 32.7761 20C38.3097 20 42.7957 15.5228 42.7957 10C42.7957 4.47721 38.3097 0 32.7761 0ZM32.7761 16.093C29.4048 16.093 26.6711 13.3647 26.6711 10C26.6711 6.63535 29.4048 3.90698 32.7761 3.90698C36.1473 3.90698 38.8811 6.63535 38.8811 10C38.8811 13.3647 36.1473 16.093 32.7761 16.093Z" fill="#101018"/>
<path d="M60.7833 0H56.8687V11.9116L48.5118 0H45.3112V20H49.2258V7.82884L57.7644 20H60.7833V0Z" fill="#101018"/>
<path d="M73.3191 0C67.7854 0 63.2994 4.47721 63.2994 10C63.2994 15.5228 67.7854 20 73.3191 20C78.8527 20 83.3387 15.5228 83.3387 10C83.3387 4.47721 78.8527 0 73.3191 0ZM73.3191 16.093C69.9478 16.093 67.2141 13.3647 67.2141 10C67.2141 6.63535 69.9478 3.90698 73.3191 3.90698C76.6903 3.90698 79.424 6.63535 79.424 10C79.424 13.3647 76.6903 16.093 73.3191 16.093Z" fill="#101018"/>
<path d="M114.128 7.44186C112.699 1.67442 109.996 0 105.335 0C100.675 0 98.0018 1.67442 96.5422 7.44186C95.0826 13.2093 93.4049 20 93.4049 20H98.438C98.438 20 101.171 9.11628 101.575 7.44186C101.98 5.76744 103.005 1.67442 105.335 1.67442C107.665 1.67442 108.661 5.76744 109.095 7.44186C109.53 9.11628 112.232 20 112.232 20H117.266C117.266 20 115.558 13.2093 114.128 7.44186Z" fill="#2871FF"/>
<path d="M127.333 0H122.299V20H127.333V0Z" fill="#2871FF"/>
</g>
<defs>
<clipPath id="clip0_6630_194285">
<rect width="126.667" height="20" fill="white" transform="translate(0.666626)"/>
</clipPath>
</defs>
</svg>

)
export default Logo;

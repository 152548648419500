const ImageUp = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 4.16602L15.8333 1.66602M15.8333 1.66602L18.3333 4.16602M15.8333 1.66602V6.66602M10.4167 2.49935H6.5C5.09987 2.49935 4.3998 2.49935 3.86502 2.77183C3.39462 3.01152 3.01217 3.39397 2.77248 3.86437C2.5 4.39915 2.5 5.09922 2.5 6.49935V13.4993C2.5 14.8995 2.5 15.5995 2.77248 16.1343C3.01217 16.6047 3.39462 16.9872 3.86502 17.2269C4.3998 17.4993 5.09987 17.4993 6.5 17.4993H14.1667C14.9416 17.4993 15.3291 17.4993 15.647 17.4142C16.5098 17.183 17.1836 16.5091 17.4148 15.6464C17.5 15.3285 17.5 14.941 17.5 14.166M8.75 7.08268C8.75 8.00316 8.00381 8.74935 7.08333 8.74935C6.16286 8.74935 5.41667 8.00316 5.41667 7.08268C5.41667 6.16221 6.16286 5.41602 7.08333 5.41602C8.00381 5.41602 8.75 6.16221 8.75 7.08268ZM12.4917 9.93114L5.44262 16.3394C5.04614 16.6998 4.84789 16.8801 4.83036 17.0362C4.81516 17.1715 4.86704 17.3057 4.96932 17.3956C5.08732 17.4993 5.35523 17.4993 5.89107 17.4993H13.7133C14.9126 17.4993 15.5123 17.4993 15.9833 17.2979C16.5745 17.0449 17.0456 16.5739 17.2985 15.9826C17.5 15.5116 17.5 14.912 17.5 13.7127C17.5 13.3091 17.5 13.1074 17.4559 12.9195C17.4005 12.6833 17.2941 12.4621 17.1444 12.2713C17.0252 12.1195 16.8677 11.9935 16.5526 11.7414L14.2215 9.87657C13.9062 9.62427 13.7485 9.49813 13.5748 9.45361C13.4218 9.41437 13.2607 9.41945 13.1104 9.46826C12.94 9.52363 12.7905 9.65947 12.4917 9.93114Z"
      stroke="#717680"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ImageUp;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { videosActions } from "../slice";
import VideoApi from "../services/api/videoAPI";

export const deleteVideo = createAsyncThunk(
  "aiVideos/deleteVideo",
  async ({ id }: { id: string }, { rejectWithValue, dispatch }) => {
    dispatch(videosActions.setIsError(null));
    try {
      const res = await VideoApi.deleteVideo(id);
      return {
        deletedImage: res,
        id,
      };
    } catch (e) {
      dispatch(videosActions.setIsError(e));
      return rejectWithValue(e);
    }
  }
);

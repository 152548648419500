import {
  EventSourceMessage,
  fetchEventSource,
} from "@microsoft/fetch-event-source";
import { LS_ACCESS_TOKEN } from "shared/api/constants/localStorage";
import { refreshToken } from "shared/api/services/refresh";

export const streamData = async (
  fetchData: {
    url: string;
    headers?: {};
    body: any;
  },
  handleNewPartialMessage: (
    text: string,
    type?: "text" | "tokens_usage",
    id?: string
  ) => void,
  onStreamClose: (id: string) => void,
  setGenerating: React.Dispatch<React.SetStateAction<boolean>>,
  currentStreamId?: string,
  onError?: (err: any) => void
) => {
  const authorizationToken = localStorage.getItem(LS_ACCESS_TOKEN);
  const ctrl = new AbortController();
  const fetchEventSourceMethods = {
    method: "POST",
    body: fetchData.body,
    signal: ctrl.signal,
    onmessage(event: EventSourceMessage) {
      const parsedData = JSON.parse(event.data);

      handleNewPartialMessage(
        parsedData.chunk,
        parsedData.type,
        currentStreamId
      );
    },
    onclose() {
      // console.log("Connection closed by the server");
      onStreamClose(currentStreamId || "");
    },
    onerror(err: any) {
      onError?.(err);
      console.log("There was an error from server", err);
    },
  };
  await fetchEventSource(fetchData.url, {
    headers: {
      ...fetchData.headers,
      Authorization: `Bearer ${authorizationToken}`, // Используйте шаблонную строку
      Accept: "text/event-stream",
    },

    ...fetchEventSourceMethods,
    async onopen(res) {
      if (res.ok && res.status === 200) {
        // console.log("Connection made ", res);
        setGenerating(true);
      } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
        console.log("Client side error ", res);
        if (res.status === 401) {
          // Перехватываем 401
          try {
            refreshToken().then((newAccessToken) => {
              // Повторный запрос
              fetchEventSource(fetchData.url, {
                // Используйте новый access token

                ...fetchEventSourceMethods,
                headers: {
                  ...fetchData.headers,
                  Authorization: `Bearer ${newAccessToken}`,
                  Accept: "text/event-stream",
                },

                async onopen(res) {
                  if (res.ok && res.status === 200) {
                    setGenerating(true);
                    // console.log("Connection made ", res);
                  } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                  ) {
                    console.log("Client side error ", res);
                  }
                },
              });
            });
          } catch (error) {
            console.error("Error refreshing token:", error);
          }
        }
      }
    },
  });
};

import { ReactNode } from "react";

export enum BtnSize {
  XLARGE = "xlarge",
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
  XSMALL = "xsmall",
  XXSMALL = "xxsmall",
  MICRO = "micro",
  SQUARE_SMALL = "square_small",
}
export enum BtnColor {
  BLACK = "black",
  WHITE = "white",
  GRAY = "gray",
  RED = "red",
  CLEAR = "clear",
  BLUE = "blue",
  GRADIENT = "gradient",
}

export enum BtnText {
  START = "start",
  END = "end",
  CENTER = "center",
  BETWEEN = "between",
}

export interface TButton {
  id?: string;
  color?: BtnColor;
  children: JSX.Element | string | ReactNode;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  isFull?: boolean;
  href?: string;
  size?: BtnSize;
  className?: string | any;
  actived?: boolean;
  square?: boolean;
  isLoading?: boolean;
  to?: string;
  actioned?: boolean; //todo add animation,
  textAlign?: BtnText;
  propsOther?: any;
  animated?: boolean;
  download?: boolean | string;
  type?: "button" | "submit" | "reset" | undefined;
  target?: "_self" | "_blank" | "parent" | "top";
}

import Avatar from "shared/assets/icons/avatar-l-light";
import { useSelector } from "react-redux";
import s from "./UserAvatar.module.css";
import { classNames } from "shared/lib/classNames/classNames";
import { userSelector } from "entities/User/model/selectors";

export enum AvatarSize {
  XSMALL = "xsmall",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}
const UserAvatar = ({
  size = AvatarSize.LARGE,
  className,
  temporaryAvatar,
}: {
  size?: AvatarSize;
  className?: string;
  temporaryAvatar?: any;
}) => {
  const user = useSelector(userSelector.currentUser);

  const avatar = temporaryAvatar ?? user?.avatar_path;
  return (
    <div className={classNames(s.avatar, {}, [s[size], className])}>
      {avatar ? (
        <img
          src={avatar}
          alt="avatar"
          // onError={() => setAvatar("https://i.pravatar.cc/305")}
        />
      ) : (
        <Avatar />
      )}
    </div>
  );
};

export default UserAvatar;

import axios from "axios";
import useHttp from "shared/api/services/api";
import { API_PREFIX, AUTH_URL } from "shared/api/constants/api";
import {
  TLoginData,
  TRegisterResponse,
  TRegUserData,
  TLoginResponse,
} from "./types";
import { LS_ACCESS_TOKEN } from "shared/api/constants/localStorage";

const accessToken = localStorage.getItem(LS_ACCESS_TOKEN);

// ===================== для Яндекс OAuth =====================
export const redirectToYandexAuth = (clientId: string, redirectUri: string) => {
  window.location.href = AuthApi.getYandexAuthUrl(clientId, redirectUri);
};

export default class AuthApi {
  static http = useHttp(API_PREFIX.AUTH);

  static async yandexOauth({ access_token, expires_in, token_type }: any) {
    const { data } = await AuthApi.http.post(`/oauth/yandex`, {
      access_token,
      expires_in,
      token_type,
      scope: "dev",
    });
    return data;
  }
  // Регистрация пользователя
  static async register(
    userData: TRegUserData,
    refCode: string | null = null,
    recaptchaToken?: string
  ): Promise<TRegisterResponse> {
    let url = "/register";
    const params = new URLSearchParams();
  
    if (refCode) {
      params.append("referral_code", refCode);
    }
    if (recaptchaToken) {
      params.append("recaptcha_token", recaptchaToken);
    }
  
    const queryString = params.toString();
    if (queryString) {
      url += `?${queryString}`;
    }
  
    const { data } = await AuthApi.http.post(url, userData);
    return data;
  }
  
  // Авторизация пользователя
  static async login(userData: TLoginData): Promise<TLoginResponse> {
    const { data } = await axios.post(`${AUTH_URL}/login`, userData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return data;
  }

  // Выход пользователя
  static async logout() {
    const { data } = await AuthApi.http.post(`/logout`);
    return data;
  }

  // Обновление пароля
  static async updatePassword(old_password: string, new_password: string) {
    const { data } = await AuthApi.http.post("/change-password", {
      old_password,
      new_password,
    });
    return data;
  }

  // Запрос на сброс пароля
  static async sendResetLink(email: string) {
    const { data } = await AuthApi.http.post(`/forgot-password`, { email });
    return data;
  }

  // Сброс пароля
  static async resetPassword(password: string, token: string) {
    const { data } = await AuthApi.http.post(`/reset-password`, {
      password,
      token,
    });
    return data;
  }

  // Запрос на отправку токена подтверждения
  static async requestVerifyToken(email: string) {
    const { data } = await AuthApi.http.post(`/request-verify-token`, {
      email,
    });
    return data;
  }

  // Подтверждение токена
  static async verify(token: string) {
    const { data } = await AuthApi.http.post(`/verify`, { token });
    return data;
  }

  // Запрос на отправку кода подтверждения
  static async requestVerificationCode(email: string) {
    const { data } = await AuthApi.http.post(`/request-verification-code`, {
      email,
    });
    return data;
  }

  // Подтверждение кода
  static async verifyCode(email: string, code: string) {
    const { data } = await AuthApi.http.post(`/verify-code?code=${code}`, {
      email,
    });
    return data;
  }

  // ===================== для Яндекс OAuth =====================
  // Получение ссылки авторизации на Яндексе
  static getYandexAuthUrl(clientId: string, redirectUri: string): string {
    return `https://oauth.yandex.ru/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
  }
}

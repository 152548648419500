const VolumeMax = (props) => (
  <svg
    width={props.width || "20"}
    height={props.height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4569 4.16628C17.638 5.80814 18.3337 7.82264 18.3337 9.99961C18.3337 12.1766 17.638 14.1911 16.4569 15.8329M13.1214 6.66628C13.7805 7.61114 14.167 8.76024 14.167 9.99961C14.167 11.239 13.7805 12.3881 13.1214 13.3329M8.02892 3.63768L5.39085 6.27576C5.24672 6.41988 5.17466 6.49195 5.09056 6.54348C5.016 6.58917 4.93472 6.62284 4.84969 6.64326C4.75378 6.66628 4.65187 6.66628 4.44804 6.66628H3.00033C2.53362 6.66628 2.30026 6.66628 2.122 6.75711C1.9652 6.837 1.83771 6.96449 1.75782 7.12129C1.66699 7.29955 1.66699 7.5329 1.66699 7.99961V11.9996C1.66699 12.4663 1.66699 12.6997 1.75782 12.8779C1.83771 13.0347 1.9652 13.1622 2.122 13.2421C2.30026 13.3329 2.53362 13.3329 3.00033 13.3329H4.44804C4.65187 13.3329 4.75378 13.3329 4.84969 13.356C4.93472 13.3764 5.016 13.4101 5.09056 13.4557C5.17466 13.5073 5.24672 13.5793 5.39085 13.7235L8.02892 16.3615C8.3859 16.7185 8.56439 16.897 8.71763 16.9091C8.8506 16.9195 8.98054 16.8657 9.06716 16.7643C9.16699 16.6474 9.16699 16.395 9.16699 15.8901V4.10909C9.16699 3.60424 9.16699 3.35182 9.06716 3.23493C8.98054 3.13351 8.8506 3.07969 8.71763 3.09015C8.56439 3.10221 8.3859 3.2807 8.02892 3.63768Z"
      stroke="#98A2B3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VolumeMax;
